@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import 'mixins/mixins';

.pd-works {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.pd-work-card {
	overflow: hidden;
	border-radius: 4px;
	background-color: var(--pd-base-08);
	transition: box-shadow .3s;
	width: 100%;

	@include hover-supported() {
		box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
		cursor: pointer;
	}

	&.focused {
		box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
	}

	&.with-border {
		border: 1px solid var(--pd-aux-bg-02);
		@include hover-supported() {
			border-color: transparent;
		}

		&.focused {
			border-color: transparent;
		}
	}

	&-inner {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 30px;
		box-sizing: border-box;
	}

	//Кнопки меню + избранное, статус работы
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	//Меню + кнопка добавить в избранное
	&-header-buttons {
		display: flex;
		gap: 20px;
		align-items: center;
	}

	//Название работы + бюджет + категории
	&-body {
		display: flex;
		flex-direction: column;
	}

	&-company-name {
		@include h3_r17_regular;
		margin-top: 5px;
	}

	&-city {
		@include text-12_tooltip_regular;
		margin-top: 3px;
		color: var(--pd-text-01);
	}

	//Строка с названием и бюджетом работы
	&-title-row {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		@include xl-1200 {
			flex-direction: column;
			gap: 15px;
		}
	}

	&-title-text {
		@include h1_22_bold;
		text-decoration: underline;
	}

	//Обертка с категориями
	&-categories {
		margin: 20px 0;
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		overflow: hidden;
	}

	&-description {
		word-break: break-word;
		max-height: 36px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	&-footer-company {
		font-size: 13px;
		line-height: 15px;
	}

	&-footer-slash,
		//Сроки
	&-footer-dates {
		white-space: nowrap;
		@include text_12_14ln_regular;
	}
}

// Карточка внутри рейтинга категорий специалиста
.pd-work-card-short {
	border-radius: 4px;
	background-color: var(--pd-base-08);
	transition: box-shadow .3s;
	width: 100%;
	position: relative;

	&.with-border.failed {
		border-color: rgba(244, 49, 52, 0.4);

		@include hover-supported() {
			box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(244, 49, 52, 0.16);
		}

		&.focused {
			box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(244, 49, 52, 0.16);
		}


		&::before {
			content: '';
			position: absolute;
			left: -1px;
			top: -1px;
			width: 20px;
			height: 20px;
			clip-path: polygon(0% 100%, 0% 0%, 100% 0%);
			border-radius: 4px 0 0 0;
			background-color: var(--pd-base-02);
		}
	}

	&.with-border {
		border: 1px solid var(--pd-aux-bg-02);
		@include hover-supported() {
			border-color: transparent;
		}

		&.focused {
			border-color: transparent;
		}
	}

	@include hover-supported() {
		box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
	}

	&.focused {
		box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
	}

	&-inner {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;
		cursor: pointer;
		position: relative;
		border-radius: 4px;
		padding: 20px;
		overflow: hidden;
	}

	//Аватар + title + рейтинг
	&-header {
		display: flex;
		flex: 1 1 100%;
		gap: 20px;
		overflow: hidden;
	}

	&-logo {
		width: 60px;
		height: 60px;
		border-radius: 4px;
		flex-shrink: 0;
		background-color: var(--pd-aux-bg-02);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-logo-img {
		width: 56px;
		height: 56px;
		object-fit: cover;
		border-radius: 4px;
	}

	//Иконка, если нет аватара
	&-no-logo-icon {
		color: var(--pd-aux-12);
	}

	//Колонка с названием работы и названием компании
	&-title {
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 4px;
		justify-content: flex-start;
		flex: 1 1 100%;
	}

	//Название работы
	&-title-text {
		-webkit-line-clamp: 2;
		word-break: break-word;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		overflow: hidden;
		@include text_15_medium;
	}

	//Строка с иконкой и названием компании
	&-title-company {
		@include h3_17_medium;
		display: flex;
		gap: 10px;
		align-items: center;
		color: var(--pd-text-02);

		//Уменьшаем размер иконки
		.pd-icon {
			width: 20px;
			height: 20px;
		}
	}

	//Название компании в карточке работы
	&-title-company-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
	}
}

.mobile {
	@include work-card-mobile;

	.pd-work-card-categories {
		max-height: 94px;
	}
}

@media screen and (max-width: 980px) {
	.pd-work-card {
		&-inner {
			gap: 20px;
			padding: 20px;
		}

		&-title-text {
			@include h2_19_medium;
			text-decoration: none;
		}

		&-body {
			gap: 20px;
		}

		&-footer {
			flex-direction: column;
			gap: 10px;
		}

		&-footer-slash {
			display: none;
		}
	}
}
