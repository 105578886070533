@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

/**
  Стили для страниц с карточками откликов
 */

.pd-requests {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	width: 100%;
	max-width: 100%;
	justify-content: stretch;
	margin-bottom: 20px;
	align-items: flex-start;
}

.pd-request-card {
	display: flex;
	overflow: hidden;
	border-radius: 4px;
	background-color: var(--pd-base-08);
	transition: box-shadow .3s;

	&-inner {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;
		cursor: pointer;
		position: relative;
	}

	&-header {
		display: flex;
		flex: 1 1 100%;
		gap: 20px;
		overflow: hidden;
		padding: 20px 25px 20px;
		max-width: 100%;
		//Другой отступ для карточки с откликом специалиста
		.pd-specialist-requests & {
			padding: 20px 24px 0;
			max-height: 70px;
		}
	}

	&-avatar {
		width: 80px;
		height: 80px;
		border-radius: 4px;
		flex-shrink: 0;
		background-color: var(--pd-aux-bg-03);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-avatar-img {
		border-radius: 4px;
		width: 80px;
		height: 80px;
		object-fit: cover;
	}

	//Колонка с названием работы и названием компании
	&-title {
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		flex: 1 1 100%;
		max-width: 100%;
		gap: 6px;
		justify-content: flex-start;
	}

	&-title-company {
		@include h3_17_medium;
		color: var(--pd-text-02);
		display: flex;
		align-items: center;
		gap: 10px;

		.pd-icon {
			width: 20px;
			height: 20px;
		}
	}

	//Название компании в карточке отклика для специалиста
	&-title-company-text {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex: 1;
	}

	//Колонка с рейтингом, статусом и тайтлом специалиста в карточке отклика для заказчика
	&-title-name {
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 16px;
		justify-content: center;
		flex: 1 1 100%;
	}

	//Название работы
	&-title-text {
		word-break: break-word;
		font-size: 17px;
		line-height: 20px;
		font-weight: 500;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;

		//Имя специалиста в карточке отлика для заказчика
		&-name {
			-webkit-line-clamp: 1;
			min-height: 20px;
		}

		& > p {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	//Контент в виде колонки (пример: карточка участника)
	&-content-column {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	//Контент в виде строки (пример: даты в карточках работы)
	&-content-row {
		display: flex;
		justify-content: space-between;
		//Для откликов добавляем отступ, чтобы не делать лишнюю обертку
		.pd-specialist-requests & {
			padding: 2px 0;
		}
	}

	//Текс с серым лейблом в карточке участника
	&-text-with-span {
		display: flex;
		gap: 10px;
		@include text_15_bold;
		line-height: 22px;
		align-items: center;

		& > span {
			@include text_13_table_regular;
			color: var(--pd-text-02);
		}
	}

	&-rating-row {
		display: flex;
		gap: 20px;
		align-items: center;
	}

	//Статус приглашения в карточке откликов
	&-request-state {
		@include text_13_table_regular;
		padding: 5px 10px;
		border-radius: 30px;
		border: 2px solid #fff;
		color: var(--pd-base-04);
		background-color: var(--pd-aux-04);
		//Цвет "В ожидании"
		&.sent,
			//Для откликов заказчика разные цвета sent
		&.sent-warn {
			color: var(--pd-base-04);
			background-color: var(--pd-aux-04);
		}

		&.sent-primary {
			color: var(--pd-base-04);
			background-color: var(--pd-aux-04);
		}

		//Цвет "Принята"
		&.accepted {
			color: var(--pd-base-05);
			background-color: var(--pd-aux-02);
		}

		//Цвет "Отклонена"
		&.declined {
			color: var(--pd-base-02);
			background-color: var(--pd-aux-01);
		}
	}

	//Кнопки удалить/принять/отклонить в карточках откликов
	&-action-buttons {
		display: flex;
		gap: 20px;

		.pd-button-secondary,
			//Обертка для подсказки
		&-tooltip {
			width: 100%;
		}

		//Для откликов специалиста кнопка "принять приглашение"
		.pd-specialist-requests & .pd-button-secondary:not(.revert):not(.warn) {
			flex-basis: 66%;
		}

		//Для откликов специалиста кнопка "отклонить приглашение"
		.pd-specialist-requests & .pd-button-secondary.revert {
			flex-basis: 33%;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	    height: 100%;
		justify-content: space-between;
		flex: 1 1 100%;
		box-sizing: border-box;
		padding: 0px 25px 20px;

		.pd-specialist-requests & {
			padding-top: 14px;
			gap: 12px;
		}
	}

	&-user-content {
		height: 86px;
		box-sizing: border-box;
		display: flex;
		gap: 17px;
		padding: 2px;
		border-radius: 4px;
		border: 1px solid var(--pd-scroll-01);
	}

	@include hover-supported() {
		box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
	}
}

@media screen and (max-width: 610px) {
	.pd-request {
		//Даты в карточке работы
		.pd-specialist-requests &-card-content-row {
			flex-direction: column;
			gap: 6px;
		}
	}

	.pd-basis-card-empty {
		border: none;

		&-title {
			@include h3_17_medium;
		}

		&-subtitle {
			@include text_13_table_regular;
		}
	}
}

@media screen and (max-width: 450px) {
	.pd-request-card {
		&-request-state {
			font-size: 12px;
			white-space: nowrap;
			padding: 5px 7px;
		}
	}
}
