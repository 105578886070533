@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

.pd-carousel-cards {
	flex: 1 1 100%;
	margin: -30px 0;

	.t-item {
		padding: 0 10px !important;
		display: flex !important;
		max-width: 340px !important;
		min-width: 340px !important;
	}

	.t-items {
		align-items: flex-start !important;
		padding: 30px 0;
		margin: 0 10px !important;
	}

	.t-scroller:after, .t-scroller:before {
		min-width: 20px !important;
	}

	&-empty-title {
		margin-bottom: 8px;
		@include h3_17_medium;
	}

	&-empty-subtitle {
		@include text_15_regular;
		color: var(--pd-aux-13);
	}
}

pdw-works-card-company-slider,
pdw-works-category-card-slider {
	display: flex;
	align-items: center;
}

//Кнопки слайдера
.pd-carousel-cards-button.mat-icon-button {
	display: flex;
	align-items: center;
	background-color: var(--pd-aux-bg-01);
	border-radius: 4px;
	height: unset;
	line-height: unset;
	align-self: stretch;

	.pd-icon {
		width: 40px;
		height: 40px;
	}

	&:not(:disabled) {
		@include hover-supported() {
			.pd-icon {
				color: var(--pd-primary);
			}
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-carousel-cards {
		margin: -30px -20px;

		.t-item {
			padding: 0 20px 0 0 !important;
		}

		.t-items {
			margin: 0 20px !important;
		}
	}
	.pd-carousel-cards-wrapper:not(.empty) {
		position: relative;
		padding-bottom: 40px;
	}
	.pd-carousel-cards-button.mat-icon-button {
		z-index: 2;
		position: absolute !important;
		bottom: 0;
		background-color: transparent;
		align-self: unset;
		width: 24px;

		.pd-icon {
			width: 24px;
			height: 24px;
		}

		&:not(:disabled) {
			@include hover-supported() {
				.pd-icon {
					color: var(--pd-primary);
				}
			}
		}

		&.prev {
			left: 0;
		}

		&.next {
			left: 40px;
		}
	}
}

