@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@import "mixins/mixins";


.pdw-public {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
	background-color: var(--pd-base-08);
	font-family: 'Montserrat', sans-serif;

	&-container {
		margin: 0 auto;
		max-width: 1624px;
		box-sizing: border-box;
		padding: 0 72px;
		position: relative;
	}

	// меню в моб.экране
	&-menu {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		left: -110%;
		top: 0;
		z-index: 1;
		background: #FFFFFF;
		transition: left .7s;

		@include mb-980 {
			display: block;
		}
	}

	&-menu.active {
		left: 0;
		height: 100%;
		overflow: visible;
		display: flex;
		justify-content: center;
	}

	&-close-menu {
		position: absolute;
		top: 30px;
		left: 36px;
		border: none;
		width: 24px;
		height: 24px;
		background: white;
		padding: 0;
		box-sizing: border-box;
		cursor: pointer;
	}

	&-menu-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 27px;
		height: 100vh;
		background: #fff;
		width: 100%;
		padding-bottom: 20px;
		box-shadow: 0 4px 15px rgba(77, 113, 149, 0.15);
	}

	&-menu-title {
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 900;
		font-size: 25px;
		line-height: 30px;
		color: var(--pd-base-black);

		& > span {
			color: var(--pd-base-12);
		}
	}

	&-menu-content {
		display: flex;
		flex-direction: column;
		margin: auto 0;
		padding-bottom: 82px;
		gap: 20px;
		align-items: center;
	}

	&-menu-center-wrapper {
		gap: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&-menu-link {
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		text-decoration: none;
		color: var(--pd-base-black);;
		@include hover-supported() {
			font-weight: 600;
			color: var(--pd-base-12);
		}
	}

	&-header {
		margin: 0 auto;
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		max-width: 1690px;
		width: 100%;
		z-index: 3;
		transition: all 300ms;
		box-shadow: 0 4px 15px rgba(77, 113, 149, 0.15);
		height: 76px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 10px;
	}

	&-header-visibility, &-footer-visibility {
		visibility: hidden;
	}

	&-header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: all 300ms;
	}

	&-header-logo {
		//@include login_30_tab_links_bold;
		//color: $font-color;
		color: var(--pd-base-black);;
		text-decoration: none;
		font-family: 'Roboto', sans-serif;
		cursor: default;
		font-weight: 900;
		font-size: 20px;
		line-height: 30px;

		& > span {
			color: var(--pd-base-12);
		}
	}

	&-header-center-wrapper {
		display: flex;
		justify-content: space-between;
		position: relative;
		width: 260px;
	}

	&-header-link {
		text-decoration: none;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		color: var(--pd-base-black);
		transition: font-weight 100ms, color 100ms;
		cursor: pointer;

		height: 76px;
		display: flex;
		align-items: center;

		@include hover-supported() {
			text-shadow: 0 0 .30px var(--pd-base-12);
			color: var(--pd-base-12);;
		}
	}

	&-services-wrapper {
		position: absolute;
		z-index: 2;
		left: -60px;
		top: 76px;

		&.list {
			left: 235px;
			top: 180px;
			position: initial;
			transition: visibility 0s;
			visibility: hidden;
		}
	}

	&-header-dropdown {
		position: absolute;
	}

	.visibility {
		visibility: visible;
	}

	&-services {
		width: 181px;
		background: #FFFFFF;
		box-shadow: inset 0 20px 15px -15px rgb(77, 113, 149, 0.15), 0 4px 4px rgba(0, 0, 0, 0.1);
		border-radius: 0 0 10px 10px;
		display: flex;
		gap: 30px;
		flex-direction: column;
		list-style-type: none;
		z-index: 2;
		align-items: center;
		padding: 20px;
		margin: 0;
		box-sizing: border-box;

		&.list {
			box-shadow: none;
			gap: 40px;
			padding: 10px 0;
		}

	}

	&-services-item {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;

		& > a {
			text-decoration: none;
			color: var(--pd-base-black);

			@include hover-supported() {
				font-weight: 600;
				color: var(--pd-base-12);
			}
		}

		&.service-not-ready a {
			color: dimgrey;
			@include hover-supported() {
				font-weight: 500;
			}
		}

		&.list {
			font-size: 18px;
			line-height: 18px;
		}
	}

	&-header-right-wrapper {
		display: flex;
		gap: 22px;
		font-family: 'Roboto', sans-serif;
	}

	//Ссылка с иконкой в футере и хедере
	&-w-icon-link {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--pd-base-12);
		text-decoration: none;
		cursor: pointer;
		padding: 10px 15px;
		height: 36px;
		border: 1px solid var(--pd-base-12);
		border-radius: 5px;
		transition: background-color 0.5s, border 0.5s;
		gap: 10px;
		@include hover-supported() {
			border: 1px solid white;
			background: white;
		}
	}

	&-icon-link-profdepo {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-decoration: none;
		width: 114px;
		height: 36px;
		border-radius: 5px;
		padding: 10px 15px;
		color: var(--pd-base-12);
		display: flex;
		align-items: center;
		box-sizing: border-box;
		justify-content: center;
		border: 1px solid var(--pd-base-12);
		transition: background 0.3s, border 0.3s;

		@include hover-supported() {
			cursor: pointer;
			border: 1px solid #E3EAFF; //* --pdw-aux-03 */
			background: none;
		}
	}

	&-w-icon-link-login.mat-raised-button.mat-button-base {
		font-family: "Montserrat", sans-serif;
		height: 36px;
		background: var(--pd-base-12);
		color: white;
		border-radius: 5px;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		transition: background 300ms;
		box-shadow: none;

		.pdw-public-icon-link-text {
			max-width: 100px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		@include hover-supported() {
			cursor: pointer;
			background: #1E1B55;
		}
	}

	&-header &-w-icon-link {
		color: var(--pd-font-color);
		@include hover-supported() {
			color: var(--pd-primary);
		}
	}

	&-footer {
		height: 318px;
		margin: 0 auto;
		max-width: 1602px;
		width: 100%;
		background-color: white;

		.pdw-divider-aux-gray {
			opacity: .1;
			width: 100%;
		}
	}

	&-footer-container {
		padding: 95px 46px 0;
		max-width: 1196px;
		width: 100%;
	}

	&-footer-wrapper {
		display: flex;
		flex-direction: column;
	}

	&-footer-img {
		position: absolute;
	}

	&-footer-row {
		margin-bottom: 42px;
		gap: 97.4px;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		//flex-wrap: wrap;
		//pdw-public-footer-row-inner класс для флекс разметки (обертка 2 ссылок)
		//Ряд с поддержкой, логином и т.д
		&-tools {
			gap: 50px;

			.pdw-public-footer-row-inner {
				display: flex;
				gap: 50px;
				align-items: center;
			}
		}

		//Ряд с публичными документами
		&-docs {
			.pdw-public-footer-row-inner {
				display: flex;
				align-items: center;
				gap: 30px;
			}
		}
	}

	&-footer-border {
		border: 1px solid rgba(29, 24, 56, 0.15);
		height: 0;
		margin-bottom: 42px;
	}

	&-footer-title {
		color: var(--pd-base-black);
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 47px;
		text-decoration: none;

		span {
			color: var(--pd-base-12);
		}
	}

	&-footer-feedback {
		display: flex;
		gap: 30px;
	}

	&-footer-documents {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.pdw-public-footer-link {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 30px;
			color: var(--pd-base-black);;
			display: flex;
			@include hover-supported() {
				color: var(--pd-base-12);
				text-shadow: 0 0 .30px var(--pd-base-12);
				//font-weight: 500;
			}
		}
	}

	&-footer-text {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: var(--pd-base-black);;
		text-align: center;
	}

	&-footer &-w-icon-link {
		//color: #fff;
		//justify-content: center;
		@include hover-supported() {
			// color: $black-text_input_helper;
		}
	}

	&-footer-text-life {
		width: 126px;
		text-align: start;
	}

	&-footer-text-link {
		width: 146px;
		text-align: start;
	}

	//Ссылка ввиде иконки на соц сети
	&-footer-social-link {
		//background-color: linear-gradient(90deg, #322D8D 0%, #263193 94.76%);
		display: flex;
		//gap: 15px;
		gap: 33px;
		align-items: center;

		.pdw-public-footer-social-link-icons {
			display: flex;
			gap: 22px;

			.pdw-icon, #pdw-yandex-icon {
				width: 40px;
				height: 40px;
				color: #322D8D;

				//background: linear-gradient(90deg, #322D8D 0%, #263193 94.76%);
				@include hover-supported() {
					//color: $black-text_input_helper;
					color: var(--pd-base-13);
				}
			}

		}

		.pdw-icon.mat-icon, #pdw-yandex-icon {
			@include hover-supported() {
				svg path:nth-of-type(1) {
					//fill: $black-text_input_helper;
					fill: var(--pd-base-13);;
				}
			}
		}
	}

	//Ссылка на документы
	&-footer-link {
		color: var(--pd-aux-12);
		@include text_13_checkbox_light;
		text-decoration: none;
		@include hover-supported() {
			color: #1E1B55;;
		}
	}
}

@media screen and (max-width: 1280px) { // при 1280px минимум отступ по бокам 15px!
	.pdw-public {
		&-header {
			max-width: 1250px;
		}

		&-header-container {
			max-width: 1250px;
		}

		&-footer {
			background-position-x: -3rem;
		}
	}
}

@media screen and (max-width: 1200px) {
	.pdw-public {
		&-footer-row {
			margin-bottom: 34.5px;
			justify-content: space-between;
		}

		&-footer-social-link {
			gap: 19px;
		}

		&-footer-text-life {
			word-break: break-all;
		}
	}
}

@media screen and (max-width: 1170px) { // для 980рх
	.pdw-public {

		&-footer {
			background-position-x: -6rem;
		}

		&-footer-row {
			gap: 0;
		}

		&-footer-container {
			padding: 75px 46px 0;
		}
	}
}

@media screen and (max-width: 980px) { //для мобильной версии до 375px
	.pdw-public {
		&-container {
			padding: 0 33px;
			margin: 0 20px;
			justify-content: space-between;
		}

		&-header-container {
			padding: 0 20px;
		}

		&-footer-container {
			max-width: 366px;
		}

		&-header-logo {
			font-weight: 900;
			font-size: 18px;
			line-height: 16px;
		}

		&-header-center-wrapper {
			display: none;
		}

		&-header {
			height: 81px;
			padding: 16.5px 0;
			top: 0;
		}

		&-icon-link-profdepo {
			display: none;
		}

		&-header-right-wrapper {
			display: flex;
			gap: 9px;
		}

		&-w-icon-link-login > .mat-button-wrapper {
		}

		&-w-icon-link-login.mat-raised-button.mat-button-base {
			display: none;
		}

		// footer
		&-footer {
			height: 470px;
			background-position-x: -25rem;
		}

		&-footer-title {
			font-weight: 700;
			font-size: 30px;
			line-height: 35px;
		}

		&-footer-text {
			margin: 37px 0 0;
		}

		&-footer-feedback {
			margin-top: 37px;
			flex-direction: column;
			align-items: center;
			gap: 20px;
		}

		&-footer-feedback > p {
			margin: 0;
			width: 146px;
			text-align: center;
		}

		&-footer-border {
			max-width: 300px;
			margin-bottom: 26px;

			&.menu {
				width: 150px;
				height: 0;
				border: 1px solid rgba(29, 24, 56, 0.15);
				margin: 40px 0;
			}
		}

		&-footer-row {
			flex-direction: column;
			margin-bottom: 22px;
		}

		&-footer-documents {
			flex-direction: column;
			height: 135px;
		}

		&-footer-social-link {
			flex-direction: column;
		}

		&-footer-text-life {
			text-align: center;
		}

		&-menu-button.mat-raised-button.mat-button-base {
			width: 100%;
			min-width: 178px;
			padding: 10px 15px;
			font-family: "Montserrat", sans-serif;
			background: var(--pd-base-12);
			color: white;
			border-radius: 3px;
			transition: background 300ms;
			@include h4-16-semi-bold;

			@include hover-supported() {
				cursor: pointer;
				background: #1E1B55;
			}
		}
	}
}

