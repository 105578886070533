@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

//Стили внутри ячейки
.pd-notification-card {
  width: 100%;

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--pd-aux-11);
    padding: 19px 21px;
    border-radius: 4px;
  }

  &-body {
    @include hover-supported(){
      border-color: var(--pd-aux-bg-03);
      background-color: var(--pd-aux-bg-03);
    }
  }

  &-title {
    @include text_13_table_regular;
    display: flex;
    gap: 5px;
    .pd-link{
      white-space: unset;
    }
  }

  &-text {
    @include text_15_regular;
    margin: 2px 0 6px;
  }

  &-bottom {
    display: flex;
    gap: 4px;
    color: var(--pd-text-01);
    @include text_11_input_helper_regular;
  }

  &-creator {
    text-decoration: underline;
    color: inherit;
  }

  &-creator {
    @include hover-supported(){
      color: var(--pd-font-color);
    }
  }

  &-buttons {
    display: flex;
    gap: 20px;
  }

}

//Стили для таблицы
.pd-notifications-table {
  &.mat-table {
    width: 100%;
  }

  & .mat-row {
    border: none;
    min-height: unset;
  }

  //Строка с карточкой
  & &-card-row {
    margin-bottom: 24px;
  }

  //Строка с датой
  & &-header-row {
    margin-bottom: 20px;
    margin-top: -4px;
  }
  & &-header-row:first-child{
    margin-top: 0;
  }

  & .mat-row:last-child {
    margin-bottom: 0;
  }

  & .mat-cell {
    padding: 0;
    width: 100%;
  }

  //Разделитель по месяцам в хронологии
  &-header-date {
    margin-left: 20px;
    position: relative;
    text-transform: capitalize;
    @include text_15_medium;
  }

  &-header-date:before {
    content: '';
    width: 9px;
    height: 13px;
    border-radius: 4px;
    background-color: var(--pd-aux-12);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
  }
}

@media screen and (max-width: 450px) {
  .pd-notification-card {
    &-content {
      max-width: 202px;
    }
  }
}

////Стили внутри ячейки
//.pd-notification{
//  &-body{
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//  }
//  &-title{
//    @include text_13_table_regular;
//    display: flex;
//    gap: 5px;
//  }
//  &-text{
//    @include text_15_regular;
//    margin: 2px 0 6px;
//  }
//  &-bottom{
//    display: flex;
//    gap: 4px;
//    color: $black-text_input_helper;
//    @include text_11_input_helper_regular;
//  }
//  &-creator{
//    text-decoration: underline;
//    color: inherit;
//  }
//  &-creator:hover{
//    color: $font-color;
//  }
//  &-buttons{
//    display: flex;
//    gap: 24px;
//  }
//}
