@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

//Компонент фильтра для карточек
.pd-filter{
  &-header{
    padding: 20px;
  }

  &-body{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &-field-title{
    @include text_13_table_regular;
    color: var(--pd-text-02);
  }

  &-footer .pd-button-secondary{
    width: 100%;
  }
}
