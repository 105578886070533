@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-chat {
  display: flex;
  height: 100%;
  position: relative;
  //justify-content: space-between;
  //background: var(--pd-base-08);

  &-requests-list {
    width: 100%;
    position: relative;
    flex-shrink: 0;
    transition: all .3s;
  }

  &.selected > &-requests-list {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &.selected > &-messenger {
    left: 0;
  }
  //Стили для аккордеона
  &-panel-title{
    display: flex;
    gap: 12px;
    align-items: center;
    max-width: calc(100% - 40px);

    & > .pd-icon-button.mat-icon-button{
      padding: 6px;
      margin: -6px;
    }
  }
  //Для аватарки + фамилии
  &-panel-title-inner {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &.link{
      color: var(--pd-font-color);
      text-decoration: none;
      @include hover-supported(){
        color: var(--pd-base-01);
      }
    }
  }
  //Обертка для картинки
  &-panel-title-img-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background: linear-gradient(200.87deg, #BDCFDB 20.98%, rgba(189, 207, 219, 0.1) 91.37%);
    & > .pd-profile-online-status-into{
      bottom: 5px;
      right: 5px;
    }
  }
  //Картинка
  &-panel-title-img{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    object-fit: cover;
  }

  &-panel-title-text{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include text_15_medium;
  }

  &-requests {
    overflow: hidden;
    height: 100%;
    padding: 0;
    position: relative;
    // двигаю слово поиск
    .pd-input-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0.5em 20px 0 57px;
    }
  }

  // позиция иконики в поле "поиск"
  &-requests-form-field-svg {
    position: absolute;
    left: -38px;
    bottom: 13px;
  }

  // разметка заголовка на паловине откликов
  &-requests-header {
    height: 64px;
    width: 100%;
    background: #F4F5F6;
    display: flex;
    align-items: center;

    p {
      padding-left: 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 22px;
      color: var(--pd-base-03);
    }
  }

  // заголовок табов
  //&-requests-tab-group .mat-tab-header {
  //  padding: 0 25px;
  //}
  //
  //&-requests-tab-group.mat-tab-group {
  //  height: calc(100% - 55px);
  //}
  //
  //&-requests-tab-group .mat-tab-label {
  //  height: 62px;
  //}

  //&-requests-tab-group-link {
  //  display: flex;
  //  padding: 0 15px;
  //  gap: 10px;
  //  font-style: normal;
  //  font-weight: 400;
  //  font-size: 15px;
  //  line-height: 18px;
  //  align-items: flex-start;
  //}

  //&-requests-scrollbar {
  //  max-height: 500px;
  //}

  &-requests-row.mat-row {
    &:not(:last-of-type){
      border-bottom: 1px solid var(--pd-aux-11);
    }
    border: none;
  }

  &-requests-cell.mat-cell {
    padding: 20px;
    cursor: pointer;
  }
  //Обертка для элемента из списка чатов
  &-requests-item {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  //Аватарка + текст
  &-requests-cell-body{
    display: flex;
    gap: 20px;
    flex: 1;
    flex-basis: 418px;
    overflow: hidden;
  }

  //Обертка для последнего сообщения и имени пользователя
  &-requests-cell-inner {
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 90px);
    //max-width: 83%;
    //height: 60px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    //padding-left: 8px;

    p {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  app-work-requests-chat-messenger-form{
    flex-basis: 54px;
    flex-shrink: 0;
  }

  //Инфо о последнем сообщении
  &-requests-cell-notice {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-requests-cell-notice-row{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  &-requests-cell-notice-time{
    opacity: .5;
  }

  &-requests-cell-notice-count{
    background-color: var(--pd-primary);
    padding: 2px 5px 1px;
    opacity: .8;
    border-radius: 20px;
    @include text_11_input_helper_regular;
    color: var(--pd-base-08);
  }

  //размеры одиночной галочки
  &-requests-sent.mat-icon {
    height: 12px;
    width: 12px;
  }

  //размеры двойной галочки
  &-requests-received.mat-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }

  &-requests-form-field {
    margin-top: 20px;
    padding: 0 25px;
  }

  &-requests-cell-company {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &-requests-cell-work {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #34303D;
    opacity: 0.7;
  }

  &-requests-cell-accepted {
    color: var(--pd-primary);
    opacity: .7;
    font-size: 13px;
    font-weight: 550;
    line-height: 16px;
  }
  //Имя фамилия пользователя внутри списка чатов
  &-requests-cell-user-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &-requests-cell-message {
    @include text_13_table_regular;
    opacity: .7;
  }


  &-requests-cell-item-work_name {
    font-weight: 700;
  }

  // обертка лого пользователя внутри чата
  &-requests-cell-img-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &-requests-cell-wrapper > mat-icon,
  &-requests-cell-wrapper > mat-icon > svg,
  &-requests-cell-img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  // разметка загаловка мессенджера
  &-messenger-header-wrapper {
    height: 64px;
    width: calc(100% - 46px);
    background: #F4F5F6;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 26px;

    & > div > div {
      display: flex;
      align-items: center;
    }
  }

  // шрифт и разметка текста имени пользолвателя в заголовке мессенджера
  &-messenger-header-user {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--pd-base-03);
    margin-right: 10px;
  }

  // шрифт текста названия компании в загаловке мессенджера
  &-messenger-header-company {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--pd-base-03);
    opacity: 0.7;
  }

  // шрифт статуса в загаловке мессенджера
  &-messenger-header-status {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    opacity: 0.7;

    &.online {
      color: var(--pd-base-05);
    }
  }

  // разметка мессенджера
  &-messenger {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    animation: .5s chatAppearance;
  }

  &-messenger-received.mat-icon {
    height: 16px;
    width: 16px;
  }

  &-messenger-sent.mat-icon {
    height: 12px;
    width: 12px;
  }

  &-messenger-time-header {
    display: flex;
    justify-content: center;

    &-row {
      border: none;
    }
  }

  &-messenger-row {
    border: none;
    margin: 10px 0;
  }

  &-messenger-cell {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 5px 30px !important;
    position: relative;
  }

  // стиль обычного сообщения
  &-messenger-cell-message {
    white-space: break-spaces;
    max-width: 69%;
    min-width: 20%;
    height: 100%;
    padding: 12px 20px;
    background:  var(--pd-aux-03);
    border-radius: 6px 6px 0 6px;
    line-height: 18px;
    font-size: 12px;
    overflow: hidden;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;

    p {
      @include text_15_regular;
    }
  }

  &-messenger-cell-message-footer{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  //Время сообщения
  &-messenger-cell-message-time{
    font-size: 11px;
    line-height: 13px;
    opacity: .5;
  }

  &-messenger-edit-mark{
    font-size: 11px;
    line-height: 13px;
  }

  // изменения у получаемых обычных сообщений
  &-messenger-import > .pd-chat-messenger-cell-message {
    background: var(--pd-aux-bg-03);
    border-radius: 6px 6px 6px 0;
  }

  &-messenger-cell-system-message {
    padding: 9px 14px;
    background: var(--pd-aux-bg-03);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text_14_tabs_window_regular;
    gap: 10px;
  }


  // позиция отправленного сообщенгия и принятого сообщения
  &-messenger-import {
    justify-content: flex-start;
  }

  // позиция полученного
  &-messenger-export {
    justify-content: flex-end;
  }

  // размеры оболочки иконки в принятом сообщении
  &-messenger-cell-concluded-text-wrapper > mat-icon {
    width: 40px;
    height: 40px;

    svg {
      box-shadow: 0 0 4.4em var(--pd-base-08);;
      background: rgba(255, 255, 255, 0.201);
    }
  }

  // оболочка текста принятого сообщения
  &-messenger-cell-concluded-text-wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    div {
      width: 135px;
    }

    mat-icon {
      box-shadow: 0 0 15px 5px rgb(255 255 255 / 30%);
    }
  }

  &-messenger-start {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;

    & > p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 0;
      box-sizing: border-box;
      gap: 10px;
      border-radius: 50px;
      background-color: var(--pd-aux-bg-03);
      @include text_14_tabs_window_regular;
      width: 100%;
    }
  }

  &-messenger-number-input {
    padding-bottom: 6px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  // обертка формы
  &-messenger-wrapper {
    padding: 6px 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EAEAEA;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    mat-icon {
      width: 36px;
      height: 36px;
    }

    & > .pd-icon-button{
      //margin-bottom: 7px;
      align-self: flex-end;
    }

    &.disabled{
      opacity: .5;
    }
  }

  &-messenger-check-application {
    & > svg {
      color: var(--pd-base-08);
    }
  }

  &-messenger-form {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &-messenger-application {
    display: flex;
  }

  &-messenger-form-field {
    width: 100%;
    background: var(--pd-base-08);

    &.mat-form-field-invalid.mat-form-field-appearance-fill .mat-form-field-flex .pd-field-prefix-icon {
      color: var(--pd-warn);
    }

    &.mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0 !important;
    }
  }

  &-messenger-application-form {
    width: 44.3%;
  }

  &-messenger-button_to_icon.mat-icon-button {
    cursor: auto;
  }

  &-messenger-application-icon.mat-icon {
    width: 40px;
    height: 40px;
  }

  &-messenger-form-field > .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  &-messenger-form-field > .mat-form-field-wrapper > .mat-form-field-flex {
    background: var(--pd-base-08);
    align-items: center;
    padding-left: 8px;
  }

  &-messenger-form-field > .mat-form-field-wrapper > .mat-form-field-underline {
    width: 0;
  }

  // разметка заголовка не выбранного чата
  &-no_chose-header {
    flex-shrink: 0;
    box-sizing: border-box;
    display: block;
    top: 0;
    height: 64px;
    width: 100%;
    background: #F4F5F6;

    button {
      padding-right: 27px;
    }
  }

  // разметка не выбранного чата
  &-no_chose {
    height: 100%;
    width: 100%;
    background: var(--pd-base-08);

    &-icon {
      color: var(--pd-base-05);
    }
  }

  &-error_icon {
    color: var(--pd-base-02);
  }

  &-textarea {
    font: 500 15px / 1.2 Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }

  &-textarea.mat-input-element {
    overflow-x: hidden;
    overflow-y: auto;
    font: 500 15px / 1.2 Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    width: 100%;
    border: none;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &:disabled {
      color: inherit;
      background-color: var(--pd-base-08);
    }
  }

  &-textarea.mat-input-element:focus-visible {
    outline: none;
  }

  //надпись "время вышло"
  &-messenger-time-over {
    display: flex;
    align-items: center;

    p {
      margin: 0 13px 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }

  // иконка отправки сообщения
  &-send-icon {
    width: 30px !important;
    height: 30px !important;
  }


  //позиция треугольника у полцучаемых сообщений
  &-rectangle-message-import.mat-icon {
    display: flex;
    position: absolute;
    width: 10px;
    height: 10px;
    left: 21px;
    bottom: 5px;
    color: var(--pd-aux-bg-03);
  }

  //позиция треугольника у отправляемых сообщений
  &-rectangle-message-export.mat-icon {
    display: flex;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 5px;
    right: 21px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    color: var(--pd-aux-03);
  }


  //размер зоны для скролла
  &-scrollbar {
    max-height: 543px;
    background: var(--pd-base-08);
  }

  &-loading{
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pd-chat-menu-trigger {
  visibility: hidden;
  position: fixed;
}

.pd-chat-context-menu {
  position: fixed;
  border: 1px solid #DADADA;
  background: var(--pd-base-08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 100px;

  button {
    border: none;
    height: 50px;
    background: var(--pd-base-08);
    border-radius: 5px;
    align-self: flex-start;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  button {
    @include hover-supported() {
      background: #DADADA;
    }
  }

  button:disabled {
    background: var(--pd-base-08);
    opacity: 0.4;
  }

  .mat-icon {
    margin: 0 10px 0 5px;
  }
}

@keyframes chatAppearance {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
