@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pdw-landing {
	font-family: 'Montserrat', sans-serif;
	background-color: var(--pd-base-08);;

	&-container {
		margin: 0 auto;
		max-width: 1280px;
		padding: 0 46px;
		box-sizing: border-box;
	}

	// section "start"
	&-start {
		padding: 103px 0 0 0;
	}

	&-start-container {
		box-sizing: border-box;
		padding: 0 46px 240px;
		display: flex;
		position: relative;
		max-width: 1198px;
		overflow: hidden;
	}

	&-start-inner {
		z-index: 1;
		display: flex;
		flex-direction: column;
		color: var(--pd-base-11);
	}

	&-start-title {
		margin-bottom: 30px;
		font-style: normal;
		font-weight: 200;
		font-size: 60px;
		line-height: 70px;
		max-width: 659px;
		color: var(--pd-base-12);
		text-transform: uppercase;
	}

	&-start-buttons {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	&-start-img {
		width: 605.43px;
		height: 550px;
		position: absolute;
		top: 5px;
		right: 65px;
		opacity: 0;
	}

	&-start-img-mini {
		display: none;
	}

	&-start-img-middle {
		display: none;
	}

	// section "scope"
	&-scope {
		margin: 0 auto 115px;
		position: relative;
	}

	&-scope-container {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: 27px;
		width: 100%;
	}

	&-scope-inner {
		display: flex;
		align-items: center;
		max-height: 300px;

		&.pdw-landing-rating {
			width: 100%;
			gap: 200px;
			background: var(--main-white);
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
		}

		&.pdw-landing-money {
			flex-direction: row;
			gap: 50px;
			justify-content: space-between;
		}

		&.pdw-landing-projects {
			gap: 41px;
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			z-index: 1;
			background: var(--main-white);
		}
	}

	&-scope-image-wrapper {
		width: 421.62px;
		height: 300px;
		background: #FFFFFF;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		//transform: matrix(-1, 0, 0, 1, 0, 0);
	}

	&-scope-box-text {
		display: flex;
		flex-direction: column;
		gap: 20px;

		&.pdw-landing-money {
			margin-left: 60px;
			margin-top: 40px;
			z-index: 1;
		}

		.pdw-landing-projects {
			margin-top: 15px;
		}
	}

	&-title {
		font-style: normal;
		font-weight: 600;
		font-size: 35px;
		line-height: 35px;
		color: var(--pd-base-black);

		&.pdw-landing-money-second {
			display: none;
		}

		&.trust {
			text-align: center;
			margin-bottom: 25px;
			margin-left: -60px;
		}

		&.join {
			width: 456px;
			height: 105px;
			margin-bottom: 30px;
		}

		&.date, &.string {
			font-weight: 400;
			font-size: 10px;
			line-height: 10px;
			margin-right: 5px;
		}

		&.desc {
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
		}
	}

	&-text {
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 22px;
		color: var(--pd-base-black);

		&.rating {
			width: 442px;
			height: 66px;
		}

		&.money {
			width: 490px;
			height: 132px;
		}

		&.projects {
			width: 420px;
			height: 66px;
		}

		&.news {
			width: 387px;

			&.first {
				margin-bottom: 22px;
			}
		}
	}

	&-rating-img {
		width: 242px;
		height: 193px;
		margin: 54px 0 53px 60px;
	}

	&-money-img {
		width: 234px;
		height: 235px;
	}

	&-projects-img {
		width: 399px;
		height: 276px;
		margin: 24px 0 0 60px;
	}

	&-projects-img-mini {
		display: none;
	}

	&-left-img {
		position: absolute;
		bottom: -25px;
		left: 0;
	}

	&-rating-img-mini {
		display: none;
	}

	// section "trust"
	&-trust {
		margin-bottom: 154px;
	}

	&-trust-container {
		max-width: 1334px;
	}

	&-trust-box {
		display: flex;
		align-items: center;
		max-width: 1242px;
		justify-content: space-between;
		height: 126px;
	}

	&-gazprom-img {
		width: 154.28px;
		height: 86.08px;
	}

	&-nyagan-img {
		width: 150.69px;
		height: 70.67px;
	}

	&-sibur-img {
		width: 140.83px;
		height: 55.27px;
	}

	&-yargeo-img {
		width: 130.96px;
		height: 39.87px;
	}

	&-salym-img {
		width: 181.19px;
		height: 126.85px;
	}

	// section "join"
	&-join {
		margin-bottom: 127px;
		padding: 0 46px;
	}

	&-join-container {
		max-width: 1242px;
		height: 340px;
		background: linear-gradient(180.96deg, #EFF1F9 3.55%, #F6F8F7 63.94%, #EFF1F1 97.4%);
		border-radius: 10px;
		padding: 0;
		opacity: 0;
	}

	&-join-wrapper {
		padding: 83px 0 0 68px;
	}

	&-join-img {
		width: 450px;
		height: 406px;
		position: relative;
		top: -340px;
		right: -670px;
	}

	// section "news"
	&-news {
		margin-bottom: 121px;
		overflow: hidden;
	}

	&-news-container {
		max-width: 1382px;
		display: flex;
		justify-content: space-between;
	}

	&-news-desc {
		width: 370px;
		height: 240px;
		display: flex;
		gap: 27.5px;
		flex-direction: column;
		opacity: 0;
	}
}

@media screen and (max-width: 1280px) {
	.pdw-landing {
		&-scope {
			margin: 0 auto 130px;
		}

		&-trust {
			margin-bottom: 135px;
		}

		&-trust-container {
			max-width: 1076px;
		}

		&-news-container {
			max-width: 1198px;
		}

		&-join {
			margin-bottom: 146px;
		}

		&-join-wrapper {
			padding: 83px 0 0 40px;
		}

		&-join-img {
			top: -335px;
			right: -637px;
		}

		&-news-desc {
			gap: 16.5px;
		}

		&-text {
			&.news {
				width: 265px;
			}
		}

		&-left-img {
			bottom: -40px;
			left: -190px;
		}

		&-title.trust {
			margin-right: -35px;
		}
	}
}

// для 980рх
@media screen and (max-width: 1170px) {
	.pdw-landing {
		&-start {
			padding: 94px 0 0 0;
		}

		&-start-container {
			padding: 0 46px 197px;
			max-width: 977px;
		}

		&-start-img-middle {
			display: block;
			width: 620px;
			height: 488px;
			top: 15px;
			right: 46px;
			position: absolute;
		}

		&-scope-box-text {
			&.pdw-landing-money {
				margin-top: 46px;
				gap: 16px;
			}
		}

		&-title {
			font-weight: 600;
			font-size: 28px;
			line-height: 28px;

			&.join {
				margin-bottom: 10px;
			}

			&.trust {
				margin-right: -60px;
			}
		}

		&-text {
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;

			&.rating {
				width: 370px;
			}

			&.money {
				width: 460px;
				height: 70px;
			}
		}

		&-rating-img {
			margin: 101px 0 76px 0;
		}

		&-scope-inner {
			&.pdw-landing-rating {
				gap: 160px;
			}

			&.pdw-landing-money {
				gap: 40px;
				align-items: start;
			}

			&.pdw-landing-projects {
				gap: 34.8px;
			}
		}

		&-scope-image-wrapper {
			width: 337.3px;
			height: 240px;
		}

		&-money-img {
			width: 242px;
			height: 238px;
		}

		&-projects-img {
			display: none;
		}

		&-projects-img-mini {
			display: block;
			width: 319.2px;
			height: 205px;
			margin: 30px 0 0 66px;
		}

		&-text.projects {
			width: 336px;
		}

		&-left-img {
			bottom: -40px;
			left: -170px;
			width: 415.59px;
			height: 406px;
		}

		&-scope {
			margin: 0 auto 70px;
		}

		&-trust-container {
			width: 901px;
		}

		&-join-img {
			right: -445px;
			top: -304px;
		}

		&-news-desc {
			width: 271px;
			margin-right: 40px;
		}

		&-news-box {
			&.first, &.third {
				width: 500px;
			}

			&.second {
				width: 580px;
			}

			&.fourth {
				width: 450px;
			}
		}

		&-join-container {
			max-width: 884px; // ???
		}

		&-news-container {
			max-width: 976px;
		}

		&-start-img-mini, &-start-img {
			display: none;
		}
	}
}

//для мобильной версии до 375px
@media screen and (max-width: 980px) {
	.pdw-landing {
		&-container {
			padding: 0 18px 0;
			max-width: 361px;
			justify-content: center;
		}

		&-start {
			padding: 126px 0 0 0;
		}

		&-start-container {
			display: flex;
			flex-direction: column;
			justify-content: start;
		}

		&-start-buttons {
			flex-direction: column;
			gap: 10px;
		}

		&-start-img, &-start-img-middle {
			display: none;
		}

		&-start-img-mini {
			position: absolute;
			display: block;
			margin-top: 380px;
			right: 0;
		}

		&-scope-inner {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 35px;

			&.pdw-landing-rating {
				flex-direction: column;
				width: 100%;
				gap: 0;
				max-height: 588px;
				justify-content: flex-start;
			}

			&.pdw-landing-money {
				flex-direction: column;
				gap: 35px;
				max-height: 562px;
				align-items: center;
			}

			&.pdw-landing-projects {
				flex-direction: column-reverse;
				max-height: 489px;
				height: 489px;
				padding-top: 40px;
				justify-content: space-between;
				box-sizing: border-box;
			}
		}

		&-projects-img-mini {
			display: block;
			margin: 0;
			margin-bottom: 26px;
		}

		&-title {
			font-weight: 600;
			font-size: 25px;
			line-height: 30px;
			width: 210px;
			height: 60px;

			&.pdw-landing-money-first {
				display: none;
			}

			&.pdw-landing-money-second {
				display: block;
			}

			&.trust {
				margin: 0 0 20px -20px;
				width: 350px;
				height: 30px;
			}

			&.join {
				width: 286px;
				height: 90px;
				margin-bottom: 22px;
			}

			&.news {
				height: 28px;
			}

			&.date, &.string {
				height: 18px;
				width: 70px;
			}

			&.desc {
				height: 56px;
				width: 303px;
			}
		}

		.pdw-landing-news-title {
			height: 18px;
		}

		&-news-box {
			gap: 0;
			padding-top: 10px;
			position: absolute;

			&:nth-child(1) {
				width: 325px;
				height: 68px;
			}

			&.first {
				top: 0;
				left: 0;
			}

			&.second {
				top: 65px;
				left: -15px;
				width: 350px;
				height: 106px;
				padding-right: 24px;
			}

			&.third {
				top: 172px;
				left: 0;
			}

			&.fourth {
				top: 240px;
				left: 10px;
				width: 300px;
			}
		}

		&-text {
			font-weight: 400;
			font-size: 16px;
			line-height: 21px;

			&.rating, &.string, &.money, &.projects {
				width: 259px;
			}

			&.rating {
				height: 84px;
			}

			&.money {
				height: 80px;
				margin-top: 10px;
			}

			&.projects {
				height: 84px;
			}
		}

		&-scope-container {
			gap: 40px;
			padding: 0 12px 0;
		}

		&-scope-box-text {
			margin-left: -17px;
		}

		&-scope-image-wrapper {
			width: 350px;
			height: 300px;
		}

		&-money-img {
			width: 242px;
			height: 238px;
		}

		&-projects-img {
			margin: 0;
			margin-right: 15px;
			width: 318px;
			height: 220px;
		}

		&-left-img {
			display: none;
		}

		&-sibur-img, &-yargeo-img, &-salym-img {
			display: none;
		}

		&-join {
			margin-bottom: 70px;
			padding: 0 12px;
		}

		&-join-img {
			width: 296px;
			height: 265px;
			position: unset;
		}

		&-join-container {
			width: 350px;
			height: 546px;
			justify-content: space-between;
			display: flex;
			flex-direction: column;
		}

		&-join-wrapper {
			padding: 47px 7px 0 19px;
		}

		&-trust {
			margin-bottom: 70px;
		}

		&-trust-container {
			max-width: 356px;
		}

		&-news {
			margin-bottom: 64px;
		}

		&-news-container {
			flex-direction: column;
			gap: 52px;
		}

		&-news-desc {
			margin-left: 25px;
			height: 182px;
			gap: 20px;
		}

		&-text.news.first {
			margin: 0;
			width: 300px;
		}

		&-rating-img-mini {
			display: block;
			width: 242px;
			height: 282px;
			margin: -5px 0 0 0;
		}
	}
}

