@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

//Mat-table для отображения файлов
.pd-files{
  //Стили для ячейки
  &-cell.mat-cell{
    padding: 0;
  }
  //Стили для ряда
  &-row.mat-row{
    min-height: unset;
    border: none;
    width: 100%;
    &:not(:last-of-type){
      margin-bottom: 10px;
    }
  }
  //Обертка внутри ячейки
  &-inner{
    display: flex;
    @include text_13_table_regular;
    align-items: center;
    width: 100%;
  }
  //Тип файла
  &-type{
    font-weight: 700;
    display: inline-block;
    //margin-right: 12px;
    text-transform: uppercase;
  }
  //Размер файла
  &-size{
    color: var(--pd-text-02);
  }
  //Дата файла
  &-date{
    white-space: nowrap;
    color: var(--pd-text-01);
  }
  //Имя файла
  &-name{
    color: var(--pd-primary);
    text-decoration: underline;
    font-weight: 500;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    //margin-right: 12px;
    white-space: nowrap;
    @include hover-supported(){
      color: var(--pd-font-color);
      cursor: pointer;
    }
  }
  &-slash{
    color: var(--pd-text-02);
  }
}
