@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-work-states {
	display: flex;
	flex-direction: column;
	gap: 20px;

	&-change-wrapper {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;

		& > .pd-button.half-width {
			flex-basis: calc(50% - 8px);
		}

		& > .pd-button-secondary.half-width {
			flex-basis: calc(50% - 8px);
		}

		& > .pd-button-secondary {
			padding: 0;
		}
	}

	//Список с историей статусов работы
	&-list {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&-item {
		display: flex;
		align-items: center;

		&.fake {
			opacity: .3;
		}
	}

	&-item-icon-wrapper {
		position: relative;
		margin-right: 20px;
	}

	&-item-date {
		margin-left: auto;
		color: var(--pd-text-02);
		@include text_12_14ln_regular;
	}

	&-item-line {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 2px;
		height: 17px;
		background-color: var(--pd-font-color);

		.fake & {
			&::before,
			&::after {
				content: '';
				position: absolute;
				height: 4px;
				width: 3px;
				left: 0;
				background-color: var(--pd-base-08);
			}

			&::before {
				top: 2px;
			}

			&::after {
				bottom: 2px;
			}
		}
	}
}
