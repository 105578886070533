@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

//Модальное окно
// Убрал цвет фона, нужно добавлять обертку wrapper компоненту
.pd-complex-filter-dialog {
	mat-dialog-container.mat-dialog-container {

		box-shadow: none;
		padding: 0;
		background-color: transparent;
		overflow: unset;

		.wrapper {
			background-color: var(--pd-base-08);
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
			border-radius: 4px;
			overflow: hidden;
		}
	}
}

.pd-complex-filter-dialog {
	@include text_13_table_regular;
	max-height: calc(100vh - 92px - 50px);
	max-width: calc(100vw - 40px) !important;

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		min-height: 56px;
		margin-bottom: 6px;
		padding: 0 20px;
	}

	&-mobile-header {
		padding: 20px 20px;
		box-sizing: border-box;
		min-height: 64px;
		align-items: center;
		display: none;
		justify-content: space-between;
		background-color: var(--pd-aux-bg-03);
		@include text_15_medium;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}

	&-header-icon {
		color: var(--pd-aux-13);
	}

	&-body {
		position: relative;
	}

	&-scroll {
		max-height: calc(100vh - 62px - 92px - 50px);

		.bar_vertical {
			margin-bottom: 85px;
			margin-right: 5px;
		}
	}

	//Горизонтальный скролл для чипсов
	&-chips-scroll {
		max-width: 288px;
		@include scrollMargin(20px, true);
	}

	&-input-field {
		display: flex;
		align-items: center;
		gap: 9px;
	}

	&-content {
		padding-top: 24px;
		display: flex;
		flex-direction: column;
	}

	&-form {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 0 20px;
	}

	&-field-title {
		color: var(--pd-text-02);
	}

	//Обертка для фильтра: title + formField + chips(если существуют)
	&-field-item {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	//Два инпута на одной строке
	&-one-row-fields {
		display: flex;
		gap: 24px;
		flex-wrap: wrap;
	}

	&-half-row {
		max-width: calc(50% - 12px);
	}

	&-footer {
		display: flex;
		justify-content: space-between;
		padding: 24px 20px 24px;
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--pd-base-08);
	}

	&-footer-text {
		color: var(--pd-text-02);
		display: flex;
		align-items: center;
		flex-grow: 1;

		.pd-icon {
			margin-right: 10px;
		}
	}

	&-footer-buttons,
	&-field-date {
		display: flex;
		gap: 20px;

		.pd-button,
		.pd-button-secondary {
			flex-grow: 1;
		}
	}
}

// Для pd-filter-field на одной строчке
.one-row {
	max-width: 260px;
	width: 100%;
}

.pd-chip {
	.filter-value {
		max-width: 63px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.non-icon {
	padding-left: 20px !important;
}

.budget-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;

	.pd-taiga-input-w-prefix {
		padding-left: 20px;
	}
}
.pd-complex-filter {
	&-field-title {
		display: flex;
		align-items: center;
		gap: 10px;
		@include text_13_table_regular;
		color: var(--pd-text-02);
	}

	&-form {
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		background-color: var(--pd-base-08);
		margin-bottom: 20px;

		&-wrapper {
			border-radius: 4px 4px 0 0;
			background-color: var(--pd-base-08);
			padding: 20px;
		}
	}

	&-mobile-header {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}

@media screen and (max-width: 980px) {
	.pd-complex-filter-dialog {
		//Перебиваем инлайн стили
		max-width: 100% !important;
		width: 100% !important;
		margin: 0 !important;
		height: 100%;
		min-height: 100%;
		max-height: 100%;

		&-header {
			display: none;
		}

		&-mobile-header {
			display: flex;
		}

		&-body {
			padding-top: 64px;
			padding-bottom: 85px;
			height: 100%;
		}

		&-content {
			min-height: 100%;
			box-sizing: border-box;
		}

		&-half-row {
			max-width: 100%;
		}

		&-scroll {
			height: calc(100% - 85px - 64px);
			max-height: calc(100% - 85px - 64px);

			& > .content {
				height: 100% !important;
			}

			.bar_vertical {
				margin-bottom: 0;
			}
		}

		&-footer {
			display: flex;
			flex-direction: column;
			position: static;
			padding: 0;
		}

		&-footer-text {
			padding: 24px 20px;
			align-self: center;
		}

		&-footer-buttons {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 24px 20px;
			background-color: var(--pd-aux-bg-03);
			justify-content: center;
		}
	}
}

@media screen and (max-width: 450px) {
	.pd-complex-filter-dialog {
		.pd-button-secondary.large,
		.pd-button.large {
			width: 100%;
			max-width: 100%;
		}
	}
}
