@forward "node_modules/@profdepo-ui/core/styles/mixins";
@forward "node_modules/@profdepo-ui/core/styles/variables";
@import "mixins/components/button";
@import "mixins/typography";

/* WIDTH MIXINS */

/* Small (sm) */
$screen-sm-min: 640px;

@mixin lg-1190 {
	@media (max-width: 1190px) {
		@content
	}
}

@mixin xl-1200 {
	@media (max-width: 1200px) {
		@content
	}
}

@mixin xl-1280 {
	@media (max-width: 1280px) {
		@content
	}
}
@mixin xl-1280-min {
	@media (min-width: 1280px) {
		@content
	}
}

@mixin xl-1440-min {
	@media (min-width: 1440px) {
		@content
	}
}

@mixin xl-1440 {
	@media (max-width: 1440px) {
		@content
	}
}

@mixin xl-1512 {
	@media (max-width: 1512px) {
		@content
	}
}
@mixin xl-1512-min {
	@media (min-width: 1512px) {
		@content
	}
}
@mixin xl-1500-min {
	@media (min-width: 1500px) {
		@content
	}
}

@mixin xl-1700 {
	@media (max-width: 1700px) {
		@content
	}
}

@mixin xxl {
	@media (min-width: 1700px) {
		@content
	}
}

@mixin mb-980 {
	@media (max-width: 980px) {
		@content
	}
}
@mixin mb-980-min {
	@media (min-width: 980px) {
		@content
	}
}



@mixin sm-375 {
	@media (max-width: 375px) {
		@content
	}
}
@mixin sm-375-min {
	@media (min-width: 375px) {
		@content
	}
}

@mixin sm-610 {
	@media (max-width: 610px) {
		@content
	}
}
@mixin sm-599 {
	@media (max-width: 599px) {
		@content
	}
}

@mixin sm-250 {
	@media (max-width: 250px) {
		@content
	}
}

@mixin sm {
	@media (max-width: #{$screen-sm-min}) {
		@content
	}
}


@mixin xl-min-1450 {
	@media (min-width: 1450px) {
		@content
	}
}

@mixin xl-1220 {
	@media (max-width: 1220px) {
		@content
	}
}

@mixin on-top {
	position: absolute;
	width: 300px;
	height: 56px;
	top: -50px;
	left: 0;
}
