@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-work-history {
  &-value {
    flex-basis: 333px;

    &.draft {
      color: var(--pd-base-04);
	  text-decoration: underline;
    }

    &.accept-cell {
      color: var(--pd-base-05);
    }

    &.reject-cell {
      color: var(--pd-warn);
    }
  }

  .mat-row:last-of-type {
    border-bottom-width: 0;
  }

  &-row {
    & > .mat-cell:first-of-type {
      padding-left: 0;
      padding-right: 5px;
    }

    & > .mat-cell {
      padding: 11px 0;
      box-sizing: border-box;
      @include text_14_tabs_window_regular;

      & > p:not(:first-of-type) {
        padding-right: 10px;
      }
    }

    &.mat-row {
      min-height: 52px;
      //Для рядов с описанием (expanded) скрываем нижнюю границу
      &.description-row {
        border-bottom-width: 0;
      }

      &:last-of-type {
        border: none;
      }
    }
  }

  &-row > &-action-column.mat-cell {
    justify-content: flex-end;
    flex-basis: 250px;
    gap: 20px;
  }

  &-create-time,
  &-creator {
    color: var(--pd-text-02);
	opacity: .7;
  }

  //Параграф с временем создания работы
  &-create-time {
    flex-basis: 217px;
  }

  &-row > &-info-column.mat-cell {
    flex-basis: calc(100% - 68px);
    display: flex;
  }

  &-creator {
    flex-basis: 250px;
  }
  //Заглушка если нет данных
  &-no-data {  
    @include text_15_medium;
    color: var(--pd-text-01);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
  }
  //Обертка для кнопки-текста
  &-description-btn-wrapper {
    padding-right: 10px;
    flex-basis: 333px;
  }

  //Кнопка-текст для expand-row с описанием работы
  &-description-btn {

    &.pd-button-transparent.revert-font-color.draft {
      color: var(--pd-base-04);
    }

    & > .pd-button-transparent-text {
      @include text_14_tabs_window_regular;
      text-align: start;
    }
  }
  //Расширяющийся ряд
  &-expanded-row.mat-row {
    overflow: hidden;
    padding: 0;
    min-height: unset;
  }
  //Параграф с текстом описания
  &-expanded-description {
    @include text_14_tabs_window_regular;
    overflow: hidden;
    flex-grow: 1;
    flex-basis: 333px;
    //padding-right: 10px;
  }

  //Контейнер заглушка
  &-expanded-filler {
    flex-grow: 0;
    flex-basis: 477px;
  }

  //Ячейка для выравнивания flex-контейнера
  &-fake-column.mat-cell {
    padding: 0;
    flex-basis: 68px;
    width: 68px;
    max-width: 68px;
  }
  //Ячейка с текстом описания
  &-expanded-column.mat-cell {
    padding: 10px 5px 10px 0;
    flex-basis: calc(100% - 68px);
  }
}

.pd-work-history.draft {
	color: var(--pd-base-04);
	text-decoration: underline;
}

@media screen and (max-width: 980px) {
  .pd-work-history {
    &-info-column.mat-cell {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    &-creator,
    &-create-time,
    &-value,
    &-expanded-filler,
    &-expanded-description,
    &-description-btn-wrapper {
      flex-basis: unset;
    }

    &-create-time {
      @include text_12_14ln_regular;
    }

    &-creator,
    &-value {
      @include text_15_regular;
    }

    &-row > .mat-cell > p:not(:first-of-type) {
      padding-right: 0;
    }

    &-description-btn > .pd-button-transparent-text {
        @include text_15_regular;
    }

    &-expanded-column.mat-cell{
      padding-top: 0;
      flex-basis: 100%;
    }

    &-fake-column{
      display: none;
    }
  }

  .pd-work-history.draft {
	color: var(--pd-base-04);
	text-decoration: underline;
  }
}
