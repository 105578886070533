@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-login {
	&-fields-wrapper {
		display: flex;
		flex-direction: column;

		.pd-input-form-field {
			width: 100%;
		}

		.last {
			margin-top: 10px;
		}
	}

	&-form-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 24px;

		.pd-button {
			width: 100%;
		}
	}

	&-forgot-wrapper {
		display: flex;
		align-items: center;
		margin: 20px 0 15px 0;

		.pd-link.pd-login-link {
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			color: var(--pd-base-12);
		}
	}

	&-title {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 35px;
		line-height: 35px;
		color: var(--pd-base-black);
	}

	&-text {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 15px;
		color: var(--pd-base-black);
		margin: 20px 0;

		&.additional {
			font-size: 14px;
			color: var(--pd-base-11);
			text-align: center;
			margin: 8px 0 14px 0;
		}
	}
}

.pd-oauth-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;

	&.register {
		flex-direction: row;
		justify-content: left;
	}

	&.has-not-shown {
		display: none;
	}
}

.pd {
	&-captcha {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 530px) {
	.pd-login {
		&-title {
			font-size: 25px;
		}
	}
}

.loading-wrapper {
	margin: 0 auto;
}
