@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

//Снэк с бюджетом работы или участника
.pd-work-cost{
  &.plate-view {
    padding: 7px 16px;
    border-radius: 4px;
    background-color: var(--pd-aux-bg-02);
    min-height: 44px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
  }

  //Для карточки меньшая высота
  .pd-work-card .plate-view &{
    min-height: 40px;
  }
  .plate-view &-text{
    @include h1_22_bold;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    & > span{
      @include text_15_medium;
      color: var(--pd-text-02);
    }
  }

  &-text{
    @include h1_22_regular;
    white-space: nowrap;
  }

  &:not(.plate-view) &-text-no-cost {
    color: var(--pd-text-01);
  }
  //Цена не указана
  .plate-view &-text-no-cost{
    @include text_15_medium;
  }
}
