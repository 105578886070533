@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-button {
	// кнопка на лендинге ворка для открытия меню в моб.версии
	&.mat-raised-button.burger {
		width: 58px;
		height: 48px;
		background: var(--pd-base-12);
		border-radius: 5px;
		display: none;
		@include hover-supported() {
			background: var(--pd-base-13);
		}
	}

	&-secondary.warn {
		&.mat-raised-button {
			background-color: var(--pd-aux-warn);
			color: var(--pd-base-02) !important;
		}
	}

	//лендинг work
	&.pd-landing-button {
		height: 50px;
		border-radius: 5px;
		font-family: 'Montserrat', sans-serif;
		font-weight: 500;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background 300ms;

		// для кнопки на лендинге ворка "Ищу специалиста"
		&.find-spec {
			width: 191px;
			background: var(--pd-base-12);
			@include hover-supported() {
				background: var(--pd-base-13);
			}
		}

		// для кнопки на лендинге ворка "Ищу работу"
		&.find-work {
			width: 140px;
			background: var(--pd-aux-03);
			color: var(--pd-base-12);
		}

		// для кнопки на лендинге ворка "Больше новостей"
		&.news {
			width: 305px;
			height: 40px;
			color: var(--pd-base-12);
			background: var(--pd-aux-03);
		}

		&.find-work, &.news {
			@include hover-supported() {
				border: 1px solid var(--pd-aux-03);
				background: none;
			}
		}
	}
}

// для 980рх на лендинге ворка
@media screen and (max-width: 1170px) {
	.pd-button.pd-landing-button.find-spec {
		width: 191px;
		height: 50px;
	}
	.pd-button.pd-landing-button.news {
		width: 271px;
		height: 36px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
	}
	.pd-public-icon-link-profdepo {
		width: 126px;
		height: 40px;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
	}
}

// для 375рх на лендинге ворка
@media screen and (max-width: 980px) {
	.pd-button.pd-landing-button.find-spec {
		width: 163px;
		height: 43px;
		font-weight: 500;
		font-size: 13px;
		line-height: 13px;
	}
	.pd-button.pd-landing-button.find-work {
		width: 121px;
		height: 43px;
		font-weight: 500;
		font-size: 13px;
		line-height: 13px;
	}

	.pd-button.mat-raised-button.burger {
		display: block;
	}

	.pd-button.pd-landing-button.news {
		width: 254px;
		height: 33px;
		font-size: 13px;
		line-height: 13px;
	}
	.pd-public-w-icon-link {
		width: 146px;
		height: 34px;
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
	}
}

