@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-logout {
  &-container{
    margin: 0 auto;
    max-width: 1440px;
    padding: 100px 115px 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
    z-index: 1;
  }

  &-title {
    @include landing_50_title_bold;
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
  }

  &-subtitle{
    @include landing_19_text_regular;
    font-weight: 600;
    font-size: 70px;
    line-height: 75px;
    color: #000000;
    max-width: 607px;
  }

  &-icon.mat-icon {
    width: 592px;
    height: 592px;
    position: absolute;
    //top: 84px;
    //right: 130px;
    top: -6px;
    right: 114px;
  }
}

@media screen and (max-width: 1117px) {
  .pd-logout {
    &-subtitle{
      font-size: 53px;
      line-height: 65px;
    }
  }
}
@media screen and (max-width: 979px) {
  .pd-logout{
    margin-top: -20px;
    &-container{
      padding:0 40px 62px;
    }
    &-title-wrapper{
      gap: 1px;
      align-items: center;
      text-align: center;
    }
    &-title{
      font-size: 18px;
    }
    &-subtitle{
      font-size: 35px;
      min-width: 294px;
      line-height: 40px;
    }

  }
}
@media screen and (max-width: 750px) {
  .pd-logout {
    &-icon.mat-icon {
      width: 445px;
      height: 445px;
      top: 187px;
      right: 105px;
    }
  }
}
@media screen and (max-width: 550px) {
  .pd-logout {
    &-icon.mat-icon {
      width: 345px;
      height: 345px;
      top: 187px;
      right: 15px;
    }
  }
}
