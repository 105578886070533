@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@import "../variable";

.pd-form-hint {
	&.forgot-password,
	&.after-registration,
	&.sent-confirmation {
		font-family: "Montserrat", sans-serif;
	}

	&.confirmation-after-forgot-password {
		font-family: "Montserrat", sans-serif;
	}

	&.forgot-password {
		margin-top: -20px;
	}

	&.sent-confirmation {
		font-size: 14px;
		line-height: 16px;
		padding: 10px 15px;
		background-color: $pd-gray-02-local;
		border-radius: 5px;
		margin-top: 15px;
	}

	&.email-success-confirmation {
		font-size: 14px;
		line-height: 16px;
		padding: 10px 15px;
		background-color: $pd-gray-02-local;
		border-radius: 5px;
		margin-bottom: 15px;
	}

	&.confirmation-after-forgot-password {
		font-size: 14px;
		line-height: 16px;
		padding: 10px 15px;
		background-color: $pd-gray-02-local;
		border-radius: 5px;
	}

	&.after-registration {
		font-size: 14px;
		line-height: 16px;
		background-color: $pd-gray-01-local;
		border-radius: 10px;
		padding: 15px;
		margin-bottom: 15px;
	}

	&-compressed-text {
		letter-spacing: -0.1px;
	}
}

@media screen and (max-width: 530px) {
	.pd-form-hint.forgot-password {
		font-size: 16px;
	}
}
