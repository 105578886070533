@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@import "mixins/mixins";


pdw-side-menu {
	display: block;
	height: 100%;
	overflow: hidden;
	max-width: 240px;
	width: 100%;
	max-height: 1200px;

	@include mb-980{
		max-width: unset;
		max-height: unset;
	}
}

.pd-side-menu-scroll {
	border-radius: 4px;

	& > .t-content {
		min-height: calc(100% - 72px);
		display: flex;
		flex-direction: column;
	}
}

.pd-side-menu {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 40px;
	background-color: #fff;
	padding: 30px 20px 0;
	border-radius: 4px;

	//Стили для "открытого" меню
	transition: min-width 1050ms;

	&-header {
		background-color: var(--pd-base-08);
		border-radius: 4px;
		overflow: hidden;
		margin-bottom: 20px;
	}

	&-header-link {
		color: var(--pd-font-color);
		display: block;
		padding: 12px 20px;
		font-size: 24px;
		line-height: 28px;
		font-weight: 700;
		text-decoration: none;

		& > span {
			color: var(--pd-primary);
		}
	}

	.pd-side-menu-link-text {
		width: 100%;
		opacity: 1;
		margin-left: 20px;
	}

	//Текст у ссылки на профиль пользвалтеля
	.pd-side-menu-profile .pd-side-menu-link-text {
		margin-left: 15px;
	}

	//Обертка переключателя заказчик/спец
	.pd-button-toggle-wrapper {
		padding: 0 20px;
	}

	//переключатель заказчик/спец
	.pd-button-toggle-group {
		position: static;
		width: 100%;
		opacity: 1;
	}

	//Заглушка переключателя при свернутом меню
	.pd-side-menu-short-toggle {
		width: 0;
		opacity: 0;
	}

	//Бейдж над иконкой (отклики)
	.pd-icon.mat-badge > .mat-badge-content {
		opacity: 0;
	}

	//Бейдж у текста меню (отклики)
	.pd-side-menu-link-text.mat-badge > .mat-badge-content {
		opacity: 1;
	}

	&:not(.pd-side-menu-mobile) {

		//Основной текст ссылок в меню
		.pd-side-menu-link-text {
			transition: opacity 450ms ease-in;
			animation-name: slide-text;
			animation-duration: 700ms;
		}

		//Текст у ссылки на профиль пользвалтеля
		.pd-side-menu-profile .pd-side-menu-link-text {
			animation-name: slide-profile-text;
		}

		//переключатель заказчик/спец
		.pd-button-toggle-group {
			animation-name: slide-toggle-button;
			animation-direction: normal;
			animation-duration: 1050ms;
		}

		//Заглушка переключателя при свернутом меню
		.pd-side-menu-short-toggle {
			transition: opacity 0ms;
		}

		//Бейдж у текста меню (отклики)
		.pd-side-menu-link-text.mat-badge > .mat-badge-content {
			transition: opacity 1200ms ease-in;
		}
	}

	&-mobile {
		min-width: unset;
		//Для счетчика откликов при развернутом мобильном меню
		.pd-side-menu-link-text.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
			right: 179px;
		}
	}

	&-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&-link-active &-link-text:not(&-link-text-accent) {
		color: var(--pd-primary);
	}

	.pd-side-menu-item.mat-list-item .mat-list-item-content {
		padding: 0;
	}

	&-link-text {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-left: 0;
		font-weight: 500;
		transition: opacity 150ms ease-in, margin-left 250ms, width 150ms;
		width: 0;
		opacity: 0;

		&-accent {
			color: var(--pd-text-02);
			font-weight: 400;
		}

		&-user-name {
			color: var(--pd-base-03);
		}

		//Для счетчика откликов при развернутом меню
		&.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
			right: 49px;
		}

		&.mat-badge-medium.mat-badge-above .mat-badge-content {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	//Бейдж над иконкой (отклики)
	.pd-icon.mat-badge > .mat-badge-content {
		opacity: 1;
		transition: opacity 600ms ease-out;
	}

	//Бейдж у текста меню (отклики)
	.pd-side-menu-link-text.mat-badge > .mat-badge-content {
		opacity: 0;
		transition: opacity 100ms ease-out;
	}

	.mat-list-base &-profile.mat-list-item {
		margin-bottom: 10px;
		height: unset;
	}

	&-profile-active &-link-text:not(&-link-text-accent):not(&-link-text-user-name) {
		color: var(--pd-primary);
	}

	&-profile-img {
		width: 32px;
		height: 32px;
		border-radius: 4px;
		transition: all .2s;
		font-size: 8px;
	}

	&-profile-img-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		width: 32px;
		height: 32px;
		border-radius: 4px;
		flex-shrink: 0;
	}

	&-profile-svg-wrapper {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-profile-wrapper &-link-text {
		max-width: 130px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 0;
		font-size: 15px;

		//Тип аккаунта в меню
		&.pd-side-menu-link-text-accent {
			@include text_12_tooltip_regular;
		}
	}

	.pd-button-toggle-wrapper {
		margin: 25px 0 20px;
		padding: 0 18px;
		box-sizing: border-box;
		height: 26px;
		transition: all 550ms;
	}

	.pd-button-toggle-group {
		position: absolute;
		width: 0;
		opacity: 0;
		transition: all 0ms !important;
	}

	.pd-side-menu-short-toggle {
		display: flex;
		width: 100%;
		opacity: 1;
		transition: opacity 550ms;
	}

	.pd-side-menu-profile.mat-list-item .mat-list-item-content {
		padding: 0;
		transition: all 550ms;
	}

	.mat-list-base .mat-list-item:not(.pd-side-menu-profile) {
		height: 54px;
	}

	.mat-nav-list .mat-list-item {
		@include hover-supported() {
			background-color: transparent;
			&:not(.pd-side-menu-profile),
			&.pd-side-menu-profile .pd-side-menu-link-text:not(.pd-side-menu-link-text-accent):not(.pd-side-menu-link-text-user-name) {
				color: var(--pd-primary);
			}
		}
	}

	.mat-nav-list .mat-list-item:focus {
		background-color: transparent;

		&:not(.pd-side-menu-profile),
		&.pd-side-menu-profile .pd-side-menu-link-text:not(.pd-side-menu-link-text-accent):not(.pd-side-menu-link-text-user-name) {
			color: var(--pd-primary);
		}
	}

	.mat-list-base {
		padding-top: 0;
	}

	&-mobile-header {
		display: none;
		justify-content: space-between;
		min-height: 56px;
		align-items: center;
		padding: 0 20px;
		background-color: var(--pd-aux-bg-03);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 5;
		&-link {
			@include text_15_medium;
			text-decoration: none;
			color: inherit;
		}

		&-link > span {
			color: var(--pd-primary);
		}
	}
}

.pd-side-menu-scroll {
	max-height: 100%;
	height: 100%;
}

.company {
	&-text {
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-size: 15px;
	}

	&-icon {
		color: var(--pd-text-01);
	}
}


@media screen and (max-width: 980px) {
	.pd-side-menu-scroll {
		border-radius: 0;

		& > .t-content {
			min-height: 100%;
		}
	}
	.pd-side-menu {
		max-width: unset;
		margin: 56px 0 0;
		border-radius: 0;
		padding: 30px 20px 25px;
		height: 100%;
		//Кнопка "параметры"
		&-profile {
			.pd-icon-button {
				right: 12px;
			}
		}

		&-header {
			display: none;
		}

		&-scroll {
			& > .t-content {
				height: calc(100% - 56px) !important;
			}
		}

		&-mobile-header {
			display: flex;
		}

		&-profile-wrapper {
			width: 100%;
		}

		&-profile-wrapper &-link-text {
			max-width: calc(100% - 32px - 12px)
		}

		.mat-list-base &-profile.mat-list-item {
			margin-bottom: 30px;
			height: unset;
		}
	}
}

//Анимация основного текста ссылок в меню
@keyframes slide-text {
	from {
		margin-left: 0;
		width: 0;
		opacity: 0;
	}
	to {
		margin-left: 20px;
		width: 100%;
		opacity: 1;
	}
	60% {
		margin-left: 0;
		width: 0;
		opacity: 0;
	}
}

//Анимация текста ссылки на профиль в меню
@keyframes slide-profile-text {
	from {
		margin-left: 0;
		width: 0;
		opacity: 0;
	}
	to {
		margin-left: 15px;
		width: 100%;
		opacity: 1;
	}
	60% {
		margin-left: 0;
		width: 0;
		opacity: 0;
	}
}

//Анимация переключателя
@keyframes slide-toggle-button {
	from {
		width: 0;
		opacity: 0;
	}
	to {
		width: 100%;
		opacity: 1;
	}
	70% {
		width: 0;
		opacity: 0;
	}
}

//Анимация "шестеренки"
@keyframes slide-profile-button {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
	70% {
		opacity: 0;
	}
}
