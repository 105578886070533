@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-container {
	min-height: 100%;
	height: 100%;
	max-width: 1920px;
	margin: 0 auto;
	position: relative;
	background-color: var(--pd-aux-bg-03);

	&.mat-drawer-container {
		overflow: visible;
	}

	.mat-drawer {
		position: fixed;
		background-color: var(--pd-aux-bg-03);
	}

	.mat-drawer-side {
		margin: 20px;
		box-sizing: border-box;
		width: 100%;
		border: none;

		&::-webkit-scrollbar,
		&::-webkit-scrollbar-thumb {
			background: transparent;
			width: 0;
			height: 0;
		}

		-ms-overflow-style: none;
		scrollbar-width: none;
		padding: 0;
		overflow: hidden;

		.mat-drawer-inner-container {
			overflow: hidden;
		}
	}
}

// контейнер основного контента
.mat-drawer-content {
	background-color: var(--pd-aux-bg-03);
	display: flex !important;
	flex-direction: column;

	&:not(.pd-mobile-drawer-content) {
		margin-left: 260px !important;
		overflow: visible;
		z-index: 3;
		transition: margin-left 1050ms;
	}

	&.pd-mobile-drawer-content {
		margin-left: 0;
		overflow: visible;
		z-index: 3;
	}
}

.pd-main-page {
	//Обертка для основного контента без toolbar
	&-wrapper {
		padding: 0 20px 20px;
		max-width: 100%;
		box-sizing: border-box;
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
		z-index: 1;
	}

	height: 100%;
	max-width: 100%;

	& > .content {
		height: 100% !important;
		display: flex;
		flex-direction: column;

		app-market,
		pdw-general {
			flex: 1 1 100%;
			display: flex;
			flex-direction: column;
			z-index: 1;
		}
	}
}

pdw-general-works {
	height: 100%;
}

.pd-cards-with-filter {
	height: 100%;
	gap: 20px;
	flex-direction: row;
}

//Для растягивания компонентов по всей высоте
.pd-expanded-height {
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	overflow: hidden;
}

//Контейнер для карточек подходящих специалистов вместе с фильтром
.pd-work-matched-users-cards {
	display: flex;
	flex-direction: row;
	gap: 20px;
	flex: 1 1 100%;
}

@media screen and (max-width: 1220px) {
	.pd-cards-with-filter {
		padding: 0;
		gap: 0;

		& > .pd-expanded-height {
			padding: 0 0 0 20px;
			z-index: 1;
		}
	}
	.pd-work-matched-users-cards {
		flex-direction: column-reverse;
		padding: 0;
		gap: 0;
	}
}

@media screen and (max-width: 980px) {
	.pd-cards-with-filter {
		flex-direction: column-reverse;
		width: 100%;
		& > .pd-expanded-height {
			padding: 0 20px;
		}
	}
	.pd-main-page {
		&-wrapper:not(.with-tool-bar, .pd-cards-with-filter) {
			padding: 24px 20px 20px;
		}
	}
	.pd-container {
		.mat-sidenav {
			right: 0;
			left: 0;
			max-width: unset;
			z-index: 5;
		}
	}
	//Для карточек при скрытом фильтре и отсутствии данных
	.pd-cards-with-filter-full-empty {
		padding-top: 20px;
	}
}

@media screen and (max-width: 980px) {
	.pd-main-page-wrapper,
	.pd-main-page-wrapper:not(.with-tool-bar) {
		padding-bottom: 30px;
	}
}
