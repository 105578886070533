@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;


  // Стили нижнего контейнера
  //&-bottom {
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //  margin-bottom: 20px;
  //  padding: 0 20px;
  //}
  //
  //&-links {
  //  display: flex;
  //  gap: 40px;
  //}
  //
  //&-link {
  //  font-size: 17px;
  //  font-weight: 400;
  //  color: $main-black;
  //  text-decoration: none;
  //  padding-bottom: 6px;
  //
  //  &-active {
  //    color: $main-blue;
  //    border-bottom: 6px solid $main-blue;
  //  }
  //}
  //
  //&-tools {
  //  display: flex;
  //  gap: 30px;
  //  align-items: baseline;
  //}
  //
  //&-form-field {
  //  width: 400px;
  //}
  //
  //&-form-icon {
  //  margin: 0 23px;
  //}


.search__content, .companies__container {
  @include flex-center;
  flex-wrap: wrap;
  column-gap: 76px;
  row-gap: 30px;
  padding: 0 91px;
}


