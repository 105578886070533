@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";

/**
  Стили для страниц с карточками специалистов
 */

.pd-users {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	width: 100%;
	max-width: 100%;
	justify-content: stretch;
	margin-bottom: 20px;

	@include mb-980 {
		display: grid;
		grid-template-columns: 1fr;
	}
}
.pd-user-card {
	display: flex;
	overflow: hidden;
	border-radius: 4px;
	background-color: var(--pd-base-08);
	transition: box-shadow 0.3s;
	flex-basis: calc(50% - 10px);
	min-width: 400px;

	//Карточка спеца внутри работы
	.pd-work-executor & {
		border: 1px solid var(--pd-scroll-01);
		height: 249px;
	}

	&-inner {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;
		cursor: pointer;
		position: relative;
		padding: 20px;
		max-height: 300px;
		overflow: hidden;
	}
	//Аватар + title + рейтинг
	&-header {
		display: flex;
		gap: 20px;
		overflow: hidden;
		margin-bottom: 15px;
	}
	&-avatar {
		width: 80px;
		height: 80px;
		border-radius: 4px;
		flex-shrink: 0;
		background-color: var(--pd-aux-bg-03);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&-avatar-img {
		border-radius: 4px;
		width: 80px;
		height: 80px;
		object-fit: cover;
	}
	//Иконка, если нет аватара
	&-no-avatar-icon {
		&.mat-icon {
			width: 30px;
			height: 30px;
		}
		color: var(--pd-aux-12);
	}

	//Колонка с рейтингом и тайтлом специалиста
	&-title {
		max-width: calc(100% - 100px);
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 1 100%;
	}
	//Title + меню
	&-title-row {
		display: flex;
		justify-content: space-between;
	}
	//Имя и фамилия в карточке спецов
	&-title-text {
		@include h2_19_medium;
		display: flex;
		flex-direction: column;
		max-height: 100%;
		white-space: nowrap;
		&.short {
			width: calc(100% - 58px);
		}
		& > p {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
		}
	}
	//Кнопки пригласить / добавить в избранное
	&-title-buttons {
		display: flex;
		gap: 10px;
	}
	&-rating-row {
		display: flex;
		gap: 20px;
		align-items: center;
		& > .pd-state-snack-card {
			width: fit-content;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&-categories {
		display: flex;
		gap: 8px;
		height: 26px;
		overflow: hidden;
	}
	&-no-categories {
		@include text_15_medium;
		color: var(--pd-aux-12);
		line-height: 26px;
	}
	.pd-skill-text {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&-tasks {
		color: var(--pd-text-02);
		@include text_13_table_regular;
		.pd-work-executor & {
			max-height: 16px;
		}
	}
	//Кнопка пригласить в карточках подходящие спецы
	&-action-buttons {
		display: flex;
		gap: 20px;

		.pd-button-secondary,
      //Обертка для подсказки
    &-tooltip {
			width: 100%;
		}
	}

	&-successful-works-title {
		@include text_15_medium;
		margin-bottom: 15px;
		&.empty {
			color: var(--pd-aux-12);
		}
	}

	@include hover-supported() {
		box-shadow: 0 0 4px rgba(3, 61, 249, 0.04), 0 4px 32px rgba(3, 61, 249, 0.16);

		.pd-user-card-title-text {
			color: var(--pd-primary);
		}
	}
	&.focused {
		box-shadow: 0 0 4px rgba(3, 61, 249, 0.04), 0 4px 32px rgba(3, 61, 249, 0.16);

		.pd-user-card-title-text {
			color: var(--pd-primary);
		}
	}
}

@media screen and (max-width: 1570px) {
	.pd-user-card{
		flex-grow: 1;
	}
}
@media screen and (max-width: 980px) {
	.pd-user-card{
		&-categories {
			height: unset;
		}
	}
}
@media screen and (max-width: 610px) {
	.pd-user-card{
		min-width: 300px;
	}
}
