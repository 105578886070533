@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

.pd-recovery {
	&-form {
		display: flex;
		flex-direction: column;
		//gap: 26px;
		gap: 20px;
		.pd-link.pd-recovery-link.new-color {
			display: none;
			color: var(--pd-base-01);
		}
		&.reset-password {
			margin-top: 135px !important;
		}
		&.reset-password-without-margin {
			margin-top: 0;
		}
	}
	&-confirm-email {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
	&-confirm-email-text {
		@include text_13_table_regular;
		color: var(--pd-text-02);
		text-align: center;
		max-width: 131px;
	}
}
