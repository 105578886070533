@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";

/**
  Стили для страниц с карточками
 */
.pd-companies {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	max-width: 100%;
	justify-content: stretch;
	margin-bottom: 20px;

	@include xxl {
		display: grid;
		grid-template-columns: repeat(2,1fr);
	}
}

.pd-company-card {
	max-width: 100%;
	display: flex;
	overflow: hidden;
	border-radius: 4px;
	//border: 1px solid #C5C5C5;
	background-color: var(--pd-base-08);
	transition: box-shadow .3s;

	@include hover-supported() {
		box-shadow: 0 0 4px rgba(3, 61, 249, 0.04), 0 4px 32px rgba(3, 61, 249, 0.16);
		//Обертка для card-content или нескольких card-content
		.pd-company-card-content-wrapper {
			background-color: #fff;
		}
		//Работы в карточке компании
		.pd-company-card-work {
			background-color: var(--pd-aux-hover-01);
		}
		.pd-company-card-title-text {
			color: var(--pd-base-01);
		}
	}

	&-inner {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;
		cursor: pointer;
		position: relative;
		padding: 24px;
		gap: 15px;
	}

	//Карточка с кнопкой добавить
	&-add {
		.pd-company-card-inner {
			cursor: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: nowrap;
			gap: 10px;
			flex-direction: row;
			min-height: 185px;
		}
	}

	&-logo {
		border-radius: 4px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-logo-img {
		border-radius: 4px;
		width: inherit;
		height: inherit;
		object-fit: cover;
	}

	//Аватар + title + рейтинг
	&-header {
		display: flex;
		flex: 1 1 100%;
		gap: 20px;
		// overflow: hidden;
	}

	&-wrapper-title {
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
		gap: 8px;
		overflow: hidden;
	}

	&-title {
		max-height: fit-content;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
	}

	//Строка с названием компании
	&-title-text {
		font-size: 17px;
		line-height: 20px;
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
		box-sizing: border-box;


		& > p {
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	//Рейтинг и город
	&-sub-title {
		display: flex;
		gap: 15px;
		align-items: center;
	}

	&-region {
		max-width: calc(100% - 69px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		@include text_13_table_regular;
		color: var(--pd-text-01);

		&:empty {
			display: none;
		}
	}

	// Обертка с кол-вом работ
	&-works {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
		margin-top: 4px;
	}

	&-work-tab {
		padding: 8px 15px;
		border-radius: 4px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: fit-content;
		@include text_13_checkbox_medium;
		color: var(--pd-text-02);
		background-color: var(--pd-aux-bg-02);

		&.blue {
			background-color: var(--pd-aux-03);
			color: var(--pd-primary);
		}
	}

	//Обертка со сферами деятельности
	&-industries {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		overflow: hidden;
		height: 26px;
		flex-shrink: 0;
	}

	//Строка сферы деятельности не указаны
	&-industries-empty {
		@include text_15_medium;
		color: var(--pd-aux-12);
		line-height: 26px;
	}

	&:not(app-client-companies .pd-company-card) {
		@include xl-1280 {
			flex-grow: 1;
			max-width: unset;
		}
	}
}

app-client-companies .pd-companies .pd-company-card {
	max-width: 800px;
}

@media screen and (max-width: 1300px) {
	.pd-company-card:not(app-client-companies .pd-company-card) {
		flex-grow: 1;
		max-width: unset;
	}
}

@media screen and (max-width: 1470px) {
	app-client-companies .pd-companies .pd-company-card {
		flex-grow: 1;
		max-width: unset;
		flex-basis: 100%;
	}
}

@media screen and (max-width: 980px) {
	.pd-company-card {
		&-logo,
		&-logo-img {
			width: 80px;
			height: 80px;
		}

		&-industries {
			height: unset;
		}

		&-wrapper-title {
			padding-left: 0;
		}

		&-title,

		&-sub-title {
			flex-direction: column;
			align-items: flex-start;
			gap: 7px;

			&.empty-region {
				margin-top: 23px;
			}
		}

		&-works {
			margin-top: 7px;
		}

		&-work-tab {
			flex-basis: calc(50% - 10px);
			text-align: center;
			font-size: 11px;
			letter-spacing: 0.03em;
			padding: 8px 0;
		}
	}
}

@media screen and (max-width: 610px) {
	.pd-company-card {
		min-width: 300px;
	}
}

