@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

//layout разметка, 2 флекс-контейнера (левый/правый) во флекс контейнере

.pd-toolbar {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: var(--pd-aux-bg-03);
	padding: 0 20px 20px;
	position: sticky;
	position: -webkit-sticky;
	gap: 30px;
	z-index: 10;
	transition: all .3s ease;
	width: auto;

	//Инпут тоже красим
	//& .pd-input-form-field.mat-form-field-appearance-fill .mat-form-field-flex{
	//  background-color: $aux-background_1;
	//}

	&-align-start {
		align-items: flex-start;
	}

	&-container-align-self-start {
		align-self: flex-start;
	}

	//С Болшим отступом для страниц с табами и иконками справа
	&.with-large-side-padding {
		padding: 0 40px 20px 20px;
	}

	&.has-not-scrolled {
		z-index: 0;
	}

	&-left-container, &-right-container {
		display: flex;
	}

	&-right-container {
		gap: 30px;
		align-items: center;
	}

	&-left-container {
		align-items: center;

		&.full-width {
			width: 100%;
			flex: 1;
		}
	}

	&-right-container-sort-add {
		justify-content: space-between;
	}

	.pd-complex-filter,
	.pd-simple-any-filter {
		width: 581px;
	}

	//Отступ для кнопки
	.pd-simple-any-filter-hidden {
		margin-right: 20px;
	}
}

//Боковой фильтр
.pd-toolbar-filter {
	box-sizing: border-box;
	width: 300px;
	display: flex;
	flex-direction: column;
	position: sticky;
	flex-shrink: 0;
	transition: transform .3s ease;
}

//Контейнер с сортировкой

.pd-toolbar-sorting {
	display: flex;
	gap: 20px;
	@include text_13_table_regular;
	color: var(--pd-text-01);
	flex-wrap: wrap;
	//Обертка для кнопок
	&-buttons {
		display: flex;
		gap: 24px;
	}
}

@media screen and (max-width: 1470px) {
	app-client-companies .pd-toolbar {
		&-left-container {
			width: 100%;
		}
	}
}

@media screen and (max-width: 1220px) {
	.pd-toolbar-filter {
		background-color: var(--pd-aux-bg-03);
		padding: 0 20px 20px;
		z-index: 2;
		top: 92px;

		.pd-work-matched-users-cards > & {
			top: 157px !important;
			padding: 0 0 20px;
		}

		&.has-not-scrolled {
			z-index: 0;
		}
	}
}

@media screen and (max-width: 1140px) {
	.pd-toolbar {
		gap: 20px;

		&-left-container,
		&-right-container {
			width: 100%;
		}

		.pd-complex-filter,
		.pd-simple-any-filter {
			width: 100%;
		}

		.pd-simple-any-filter-hidden {
			margin-right: 0;
			width: 100%;
			flex: 1 1 100%;
			order: -1;
		}
	}
	//Тулбар с иконками справа и табами слева
	.pd-toolbar-with-i-buttons {
		padding: 0;
		gap: 15px;
		margin-bottom: 20px;

		.pd-toolbar-left-container {
			padding: 20px 20px 0;
		}

		.pd-toolbar-right-container {
			padding: 0 20px;
			background-color: var(--pd-aux-bg-03);
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-toolbar-sorting {
		width: 100%;
		justify-content: flex-start;
	}
	.pd-toolbar-filter{
		width: 100%;
	}
	.pd-input-form-field{
		width: 100%;
	}
	.pd-toolbar {
		width: auto;
		&,
		&.with-large-side-padding {
			padding: 20px;
		}

		//Контейнер с сортировкой и кнопкой добавить
		&-right-container-sort-add {
			gap: 20px;
			flex-wrap: wrap;
		}

		&.scrolled,
		&-filter.scrolled {
			transform: translateY(-250%);
			z-index: 1;
		}

		.pd-simple-any-filter-hidden,
		.pd-complex-filter-hidden {
			margin-right: 0;
			flex: 1 1 100%;
			order: -1;
		}
	}
	//Тулбар с кнопками добавления чего-либо
	.pd-toolbar-w-add-buttons {
		gap: 15px;
	}
	.pd-toolbar-filter {
		padding-top: 20px;
		padding-bottom: 0;
		top: 53px;

		.pd-work-matched-users-cards > & {
			top: 138px !important;
		}
	}
}

@media screen and (max-width: 900px) {
	//Тулбар с иконками справа и табами слева
	.pd-toolbar-with-i-buttons {
		&.pd-toolbar {
			padding: 0;
			gap: 15px;
			margin-bottom: 20px;
		}

		.pd-toolbar-left-container {
			padding: 20px 20px 0;
		}

		.pd-toolbar-right-container {
			padding: 16px 20px;
			background-color: var(--pd-base-08);
		}
	}
}

@media screen and (max-width: 610px) {
	.pd-toolbar-sorting {
		width: 100%;
	}
	.pd-toolbar-left-container{
		flex-direction: column;
	}
	.pd-toolbar-with-i-buttons {
		.pd-toolbar-right-container {
			justify-content: space-between;
		}
	}
	//Одна кнопка в правом контейнере
	.pd-toolbar-right-container-alone-btn {
		margin: 0 auto;
	}
	//Тулбар с кнопками добавления чего-либо
	.pd-toolbar-w-add-buttons {
		.pd-toolbar-right-container {
			gap: 10px;
		}
	}
}

//layout разметка для мобильной версии
.pd-mobile-toolbar {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: var(--pd-aux-bg-03);
	padding: 20px;
	gap: 25px;
}
