@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";

.pd-work {
	display: flex;
	gap: 20px;
	//Колонка с инфо и историей
	&-main-column {
		flex-basis: 908px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex-grow: 1;
	}

	//Колонка с чатом и статусами
	&-side-column {
		flex-basis: 500px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		overflow: hidden;
	}

	//Обертка с основными данными о работе
	&-plate-info {
		background-color: var(--pd-base-08);
		border-radius: 4px;
		box-sizing: border-box;
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 1420px;
		overflow: hidden;


	}

	//Обертка для статусов
	&-plate-states {
		//Если работа завершена не показываем аккордеон
		&.finished {
			padding: 20px;
			background-color: var(--pd-base-08);
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 4px;
			height: fit-content;
		}
	}

	&-title {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&-title-text {
		@include h1_22_bold;
		inline-size: 600px;
		overflow-wrap: break-word;
	}

	//Контейнер с кнопками изменить/добавить в избранное
	&-title-buttons {
		float: right;
		padding-left: 10px;
		display: flex;
		gap: 20px;

		&:empty {
			display: none;
		}
	}

	//Строка с названием компании, сроками, бюджетом и рейтингом
	&-preamble {
		display: flex;
		gap: 20px;
		justify-content: space-between;
	}

	//Строка с рейтингом и конейтнером названия компании и сроков
	&-company-row {
		display: flex;
		align-items: center;
		gap: 12px;
		color: var(--pd-text-02);
		//flex-wrap: wrap;
	}

	//Контейнер с названием компании и сроками
	&-company-container {
		display: flex;
		gap: 12px;
		row-gap: 5px;
		align-items: center;
		flex-wrap: wrap;
		font-weight: 400;
	}

	//Ссылка с названием компании
	&-company-link {
		text-decoration: none;
		font-size: 13px;
		line-height: 15px;
		color: inherit;
		@include hover-supported() {
			color: var(--pd-base-01);
		}
	}

	&-company-slash,
		//Сроки
	&-company-dates {
		white-space: nowrap;
		@include text_12_14ln_regular;
	}

	&-description {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 13px;
		line-height: 20px;

		p {
			word-break: break-word;
		}
	}

	&-plate-chat {
		max-height: 673px;
	}

	//Аккордеон со статусом работы
	&-state-panel {
		--expansion-panel-body-padding: 0 20px 20px;
		--expansion-panel-header-padding: 20px;
	}

	//Аккордеон со чатом
	&-chat-panel {
		--expansion-panel-body-padding: 0;
		--expansion-panel-header-padding: 10px 20px;

		& > .mat-expansion-panel-header[aria-expanded=true] {
			border-bottom: 1px solid var(--pd-aux-11);
		}

		& > .mat-expansion-panel-content {
			overflow: hidden;
		}
	}

	//Дата для последнего статуса, если работа завершена
	&-last-state-date {
		color: var(--pd-text-02);
		@include text_12_14ln_regular;
	}

	//Аккордеон с историей изменений
	&-history-panel {
		--expansion-panel-body-padding: 0 20px 5px;
		--expansion-panel-header-padding: 20px;
	}

	app-work-all-files:empty,
	pdw-work-state-change-specialist:empty,
	app-work-marks-specialist:empty,
	app-work-marks-manager:empty {
		display: none;
	}

	//Контейнер для отзывов
	app-work-marks-specialist,
	app-work-marks-manager {
		display: flex;
		flex-direction: column;
		gap: 20px;

		& > .pd-marks-card {
			border: none;
			background-color: var(--pd-base-08);
		}
	}
}


@media screen and (max-width: 1380px) {
	.pd-work {
		flex-direction: column;

		&-side-column {
			flex-direction: row;
			flex-basis: unset;
		}

		&-main-column {
			flex-basis: unset;
		}

		&-plate-states,
		&-plate-chat {
			flex: 1;
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-work {
		flex-direction: column-reverse;

		&-preamble {
			flex-direction: column;
			gap: 12px;
		}

		&-company-row {
			flex-direction: column;
			align-items: flex-start;
		}

		&-company-container {
			//flex-direction: column;
			flex-wrap: nowrap;
		}

		&-company-slash {
			display: none;
		}
	}
}
