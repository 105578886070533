@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

//Класс для контейнера с карточками
.pd-work-cards-old {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 100%;
  justify-content: stretch;
  margin-bottom: 20px;
  align-items: flex-start;
}

.pd-work-card-old {
  overflow: hidden;
  border-radius: 4px;
  background-color: var(--pd-base-08);
  transition: box-shadow .3s;
  width: 100%;
  padding: 20px 24px;
  &.short{
    padding: 20px;
  }
  &.with-border {
    border: 1px solid var(--pd-aux-bg-02);
    @include hover-supported(){
      border-color: transparent;
    }
    &.focused {
      border-color: transparent;
    }
  }

  @include hover-supported(){
    box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
  }

  &.focused {
    box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
  }

  //Карточка с кнопкой добавить
  &-add {
    min-height: 261px;
    cursor: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    flex-direction: row;
  }

  //Обертка для card-content или нескольких card-content
  &-content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
  }

  &-inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    position: relative;
    border-radius: 4px;

    .pd-divider-line-1 {
      margin: 0 25px;
    }
  }

  //Аватар + title + рейтинг
  &-header {
    display: flex;
    flex: 1 1 100%;
    gap: 20px;
    overflow: hidden;
  }

  &-logo {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    flex-shrink: 0;
    background-color: var(--pd-aux-bg-02);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-logo-img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 4px;
  }

  //Иконка, если нет аватара
  &-no-logo-icon {
    color: var(--pd-aux-12);
  }

  //Колонка с названием работы и названием компании
  &-title {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: flex-start;
    flex: 1 1 100%;

    .short & {
      gap: 4px;
    }
  }

  //Название работы
  &-title-text {
    @include h3_17_medium;
    -webkit-line-clamp: 2;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;

    .short & {
      @include text_15_medium;
    }
  }

  //Строка с иконкой и названием компании
  &-title-company {
    @include h3_17_medium;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--pd-text-02);

    //Уменьшаем размер иконки
    .pd-icon {
      width: 20px;
      height: 20px;
    }
  }

  //Название компании в карточке работы
  &-title-company-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    flex: 1 1 100%;
    box-sizing: border-box;

    //Снеки в теле карточки
    &:first-child {
      padding: 14px 0;
    }
  }

  //Даты начала окончания
  &-text-with-span {
    display: flex;
    gap: 10px;
    @include text_15_bold;
    line-height: 22px;
    align-items: center;

    & > span {
      @include text_13_table_regular;
      color: var(--pd-text-02);
    }
  }

  //Строка с датами работы
  &-content-row {
    display: flex;
    justify-content: space-between;
  }
}


@media screen and (max-width: 450px) {
  .pd-work-card-old {
    &-title-text {
      -webkit-line-clamp: 3;
    }

    //Дату перебрасываем под лейбл в карточке работы
    &-text-with-span {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }

    //Даты в карточке работы
    &-content-row {
      justify-content: flex-start;
      gap: 40px;
    }
  }
}




