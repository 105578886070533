@use "node_modules/@profdepo-ui/core/styles/_mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";


.pd-company {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	max-width: 100%;
	//brief + рейтинг
	&-join-row {
		display: flex;
		flex: 1 0 100%;
	}

	&-row-gap {
		display: flex;
		flex: 1 0 100%;
		column-gap: 20px;

	}

	&-rating,
	&-brief {
		display: flex;
		background-color: var(--pd-base-08);
		padding: 20px;
		width: fit-content;
	}

	//Logo + legal-details
	&-brief {
		gap: 20px;
		width: 100%;
		border-radius: 4px 0 0 4px;
	}

	//Плитка с рейтингом
	&-rating {
		border-radius: 0 4px 4px 0;
		flex: 1 0 35%;
		justify-content: flex-start;

		@include xl-1440 {
			width: 100%;
		}
		//Компонент рейтинга
		.pd-profile-rating-content.no-rating {
			align-items: flex-start;

			.pd-profile-rating-title {
				margin-bottom: 40px;
			}

			.pd-rating-big-one-wrapper {
				top: -21px;
			}
		}

		.pd-rating-big-one-wrapper {
			top: 9px;
		}

		.pd-profile-rating-title {
			margin-bottom: 30px;
		}
	}

	//Название, email, телефон компании
	&-legal-details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 22px;
		max-width: 815px;
		//email, телефон компании
		.pd-link-w-icon-black {
			@include text_15_medium;

			&:not(.not-hoverable) {
				@include hover-supported() {
					.pd-icon {
						color: var(--pd-primary);
					}
				}
			}
		}
	}

	//Блоки
	&-industries,
	&-description {
		width: 100%;
		overflow: hidden;
		flex-shrink: 0;
		@include mb-980 {
			width: 100%;
			height: fit-content	;
		}
		@include xl-1280-min {
		flex: 1 0 calc(50% - 10px);
		}

		.pd-snackbar {
			width: 100%;
			max-width: 100%;
			z-index: 2;
			box-sizing: border-box;

			.pd-snackbar-container {
				justify-content: space-between;
			}
		}
	}


	//Блок "работы"
	&-works-brief,
	&-marks {
		flex: 1 1 100%;
		max-width: 100%;
	}

	&-works-brief &-block-body {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&-block {
		--expansion-panel-body-padding: 0 20px 20px;
		--expansion-panel-header-padding: 20px;
		//Для закрытых аккоредонов не показываем кнопку редактировать
		&:not(.mat-expanded) .pd-button-secondary {
			display: none;
		}

		&.empty {
			--expansion-panel-body-padding: 0;
		}
	}

	//Обертка для тайтла с кнопкой "редактировать"
	&-title-wrapper,
		//Обертка для тайтла блока
	&-block-title-wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
		overflow: hidden;
	}

	//Название компании
	&-title,
		//Название блока
	&-block-title {
		@include h1_22_bold;
	}

	&-title {
		word-break: break-word;
	}

	&-block-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-description-text,
	&-description-text .pd-link {
		@include text_15_regular;
		line-height: 24px;
		word-break: break-word;
	}

	&-works-count-wrapper {
		display: flex;
		align-items: center;
		gap: 24px;

		.pd-basis-card-work {
			flex-grow: 0;
		}
	}
}

.pd-company-edit-btn {
	position: absolute;
	top: 54px;
	left: 0;
	z-index: 2;
	width: 160px;
	background-color: transparent;
	color: var(--pd-base-08);

	&:hover {
		opacity: 0.9;
		cursor: pointer;
	}
}

/*
  Лого компании
*/
.pd-company-logo {
	&-wrapper {
		position: relative;
		background-color: var(--pd-aux-bg-03);
		width: 164px;
		height: 164px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-image {
		display: block;
		width: 160px;
		height: 160px;
		border-radius: 4px;
		object-fit: cover;
		position: absolute;
	}

	&-empty-icon {
		&.mat-icon {
			width: 63px;
			height: 63px;
		}

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--pd-aux-12);

		&.can-update {
			top: 31px;
			transform: translate(-50%, 0);
		}
	}

	&-btn.mat-raised-button {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}

	@include mb-980 {
		display: flex;
	}
}

/**
  Статичная таблица на дивах
 */
.pd-company-fake-table {
	margin-bottom: 24px;

	&-header-row {
		margin-bottom: 18px;
	}

	&-content-row:not(:last-of-type) {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 1700px) {
	.pd-company {
		&-join-row {
			flex-direction: column;
			gap: 20px;
		}

		&-row-gap {
			flex-direction: row;
			justify-content: space-between;
			gap: 20px;

			@include xl-1440 {
				flex-direction: column;
				justify-content: flex-start;
			}
		}

		&-brief,
		&-rating {
			min-width: unset;
			border-radius: 4px;
		}

		&-rating {
			justify-content: flex-start;
			flex: unset;
		}

		//Рейтинг
		.pd-profile {
			&-rating-content.no-rating {
				.pd-profile-rating-title {
					margin-bottom: 20px;
				}
			}

			&-rating-title {
				margin-bottom: 33.5px;
			}
		}
	}
}

@media screen and (max-width: 1050px) {
	.pd-company {
		//Блоки
		&-industries,
		&-description {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-company {
		&-brief {
			flex-direction: column;
			gap: 14px;
		}

		&-legal-details {
			gap: 14px;
		}
	}
}

@media screen and (max-width: 610px) {
	.pd-company {
		&-button-text {
			display: none;
		}

		&-works-count-wrapper {
			flex-direction: column;
			gap: 10px;

			.pd-basis-card-work {
				padding: 24px;
				gap: 14px;
				width: 100%;
				flex-direction: row;
				height: 90px;

				&-count {
					font-size: 36px;
					line-height: 42px;
					width: 64px;
					text-align: start;
				}

				&-text {
					@include text_15_regular;
					line-height: 16px;
					text-align: start;
					max-width: 100px;
				}
			}
		}

		&-description-text .pd-link {
			white-space: unset;
		}
	}
}
