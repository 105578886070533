@mixin h1-35 {
	font-size: 35px;
	font-weight: 700;
	font-family: Roboto, serif;
}

@mixin bold-17 {
	font-size: 17px;
	font-weight: 700;
}

@mixin b1-20-regular {
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
}
@mixin h1-35-semi-bold {
	font-weight: 600;
	font-size: 35px;
	line-height: 40px;
}

@mixin h2-25-bold {
	font-size: 25px;
	font-weight: 600;
	line-height: 30px;
}

@mixin h3-20-semi-bold {
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
}

@mixin h4-16-semi-bold {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
}

@mixin b2-16-regular {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}

@mixin b4-13-regular {
	font-size: 13px;
	font-weight: 500;
	line-height: 13px;
}
@mixin b4-12-regular {
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
}

@mixin b3-14-regular {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

@mixin h5-15-semibold {
	font-size: 14px;
	font-weight: 600;
	line-height: 18px;
}

@mixin montserrat {
	font-family: Montserrat, serif;
}
