@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";
//Карточки с отзывами специалиста
.pd-marks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  margin-bottom: 30px;

  @include xl-1280-min {
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  @include xl-1512-min  {
    grid-template-columns: repeat(3,1fr);
  }
  //Для первой страницы без кнопки "показать еще" нет отступа снизу
  &.first-page-without-padding{
    margin-bottom: 0;
  }

  &-card {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--pd-scroll-01);
    @include hover-supported() {
      border-color: transparent;
      cursor: pointer;
      border: 1px solid var(--pd-scroll-01);
    }

    &.focused {
      box-shadow: 0px 0px 4px rgba(3, 61, 249, 0.04), 0px 4px 32px rgba(3, 61, 249, 0.16);
    }
  }

  &-card-inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 16px;
  }

  //Обертка лого + тайтл
  &-card-header {
    display: flex;
    gap: 20px;
  }

  //Лого компании
  &-card-avatar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    flex-shrink: 0;
    background-color: var(--pd-aux-bg-03);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-card-avatar-img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 4px;
  }

  //Иконка, если нет аватара
  &-card-no-avatar-icon {
    &.mat-icon {
      width: 30px;
      height: 30px;
    }

    color: var(--pd-aux-12);
  }

  //Обертка для названия работы + компании
  &-card-title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
  }

  &-card-title-text,
  &-card-comment {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  //Название работы
  &-card-title-text {
    @include text_15_medium;
    -webkit-line-clamp: 2;
    min-height: 36px;
  }

  &-card-title-text.fit-height {
    @include text_15_medium;
    -webkit-line-clamp: 2;
    min-height: 0;
  }

  //название компании + иконка
  &-card-title-company-row {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--pd-text-02);

    .pd-icon {
      width: 20px;
      height: 20px;
    }
  }

  //Название компании
  &-card-title-text-company {
    white-space: nowrap;
    @include h3_17_medium;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  //Обертка с оценками
  &-card-rating {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  //Текст + звезда
  &-card-rating-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  //Текст внутри rating-item
  &-card-rating-text,
  &-card-body {
    @include text-13_table_regular;
  }

  //modifyTime + comment
  &-card-body {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &-card-modify-time{
    color: var(--pd-text-02);
    display: flex;
    gap: 8px;
    align-items: center;
    .pd-icon {
      width: 20px;
      height: 20px;
    }
  }

  &-card-comment {
    line-height: 18px;
    -webkit-line-clamp: 5;
    //Если нет комментария
    &.empty{
      color: var(--pd-text-01);
    }
  }

  //Текст вы посмотрели все отзывы
  &-no-remain {
    text-align: center;
    @include h3_r17_regular;
    color: var(--pd-text-01);
  }
  //Обертка загрузки
  &-loading{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;
  }
  &-empty-title{
    margin-bottom: 8px;
    @include h3_17_medium;
  }
  &-empty-subtitle{
    @include text_15_regular;
    color: var(--pd-aux-13);
  }
}
