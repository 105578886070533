@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

//Дерево в виде аккордеона (сферы деятельности компании)
.pd-tree-industry {
  //Сдвигаем ниже, чтобы последний элемент был по макету
  &.mat-tree{
    margin-bottom: -15px;
  }
  //Родительская ветка
  &-parent-node.mat-tree-node {
    min-height: 52px;
    justify-content: space-between;
    padding: 13px 0;
    box-sizing: border-box;
    position: relative;
    @include text_15_medium;

    @include hover-supported(){
      cursor: pointer;
    }

    .pd-icon-button{
      position: relative;
    }

    &:first-of-type{
      min-height: 38px;
      padding-top: 5px;
      align-items: flex-start;
      .pd-icon-button{
        height: 24px !important;
        line-height: 24px !important;
      }
    }

    &:not(:first-of-type) {
      border-top: 1px solid var(--pd-aux-11);
    }
    //Для открытых веток переворачиваем стрелку
    &[aria-expanded=true] {
      color: var(--pd-text-02);
      .pd-icon-button{
        transform: rotate(180deg);
      }
    }

    .pd-icon-button{
      transition: transform .3s;
    }

    &.hidden{
      display: none;
    }
  }

  //Вложенная ветка
  &-node.mat-tree-node {
    min-height: unset;
    margin-bottom: 15px;

    //Верхняя граница для следующей ветки
    & + .pd-tree-industry-parent-node{
      border-top: 1px solid var(--pd-aux-11);
    }
    &.hidden{
      display: none;
    }
  }
}
