@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@import "mixins/mixins";

.pd-card {
	//Иконка избранные
	&-bookmark {
		@include hover-supported() {
			svg {
				fill: var(--pd-primary);
			}
		}
	}

	//Иконка избранные (добавлен в избранные)
	&-bookmark-favorite {
		color: var(--pd-primary);

		svg {
			fill: var(--pd-primary);
		}

		@include hover-supported() {
			svg {
				fill: none;
			}
		}
	}
}

/**
  Стили для страниц с карточками
 */
.pd-basis {

	//Текст вы посмотрели всех специалистов
	&-no-remain {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 56px;
		background-color: #fff;
		border-radius: 4px;
		text-align: center;
		@include text_15_regular;
		color: var(--pd-text-01);
	}

	//Пустой поисковый запрос
	&-card-empty {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		flex: 1 1 100%;
		border-radius: 4px;
		text-align: center;
		padding: 25px 0;
		//border: 1px solid $black-line_1;
		background-color: var(--pd-base-08);

		&-inner {
			display: flex;
			flex-direction: column;
			gap: 15px;
			align-items: center;
		}

		.pd-icon.mat-icon {
			color: var(--pd-aux-12);
			width: 100px;
			height: 100px;
		}

		&-title {
			@include h2_19_medium;
			max-width: 298px;

			@include mb-980 {
				max-width: 208px;
			}
		}

		&-subtitle {
			@include text_15_regular;
			max-width: 600px;
		}

		&-buttons {
			flex-direction: column;
			display: flex;
			gap: 20px
		}
	}

	//Нет карточек в откликах             // только в works-requests-specialist/manager cards
	&-card-empty-requests {
		.pd-basis-card-empty-subtitle {
			max-width: 500px;
		}
	}

	//С кнопкой добавить организацию             // в works-card, general-companies
	&-card-empty-with-add-button {
		.pd-basis-card-empty-inner {
			gap: 25px;
		}

		.pd-icon.mat-icon {
			color: var(--pd-aux-12);
			border-radius: 10px;
			overflow: hidden;
		}
	}
}

@media screen and (max-width: 610px) {
	.pd-basis {
		//Кнопка добавить организацию
		&-add-button { // ???
			align-self: center;
			width: 100%;
		}
	}

	.pd-basis-card-empty {
		border: none;

		&-title {
			@include h3_17_medium;
		}

		&-subtitle {
			@include text_13_table_regular;
		}
	}
}

