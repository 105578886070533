@use "../../node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "mixins/mixins";

.pd-header {
	padding: 0 20px;
	background-color: #fff;
	display: flex !important;
	min-height: 52px;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	gap: 32px;

	//Контейнер с тайтлом
	&-title {
		display: flex;
		align-items: center;
		gap: 20px;
		flex: 1 1 100%;
		max-width: calc(100% - 180px);
		//Ширина хлебных крошек
		.pd-bread-crumbs {
			max-width: 100%;
		}
	}

	&-title-text {
		@include h2_19_medium;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	&-icons {
		display: flex;
		gap: 32px;
		align-items: center;
	}

	&-icon {
		position: relative;
		align-self: center;
	}
}

//Внешняя обертка
.app-header {
	flex: 0 1 auto;
	background-color: var(--pd-aux-bg-03);
	top: 0;
	left: 0;
	right: 0;
	padding: 20px;
	z-index: 10;
	position: -webkit-sticky;
	transition: transform .3s ease;

	//Не работает в animation
	//transition: left 350ms ease-in;

	//Скрываем header в мобильной версии при открытии меню
	&.hidden {
		@include mb-980 {
			z-index: 3;
		}
	}

	&:not(.app-header-mobile-content) {
		left: 241px;
	}

	&.app-header-mobile-content {
		position: fixed;
		left: 0;
		&.hidden {
			position: static;
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-header {
		background-color: #fff;
		border-radius: 0;
		border-bottom: 1px solid var(--pd-aux-11);

		&-title-text {
			@include text_13_table_regular;
		}
	}
	.app-header {
		padding: 0;

		&.scrolled {
			transform: translateY(-200%);
			z-index: 1;
		}
	}
}
