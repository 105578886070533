@use 'node_modules/@angular/material/index' as mat;
@use "node_modules/@profdepo-ui/core/styles/functions" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "placeholders" as *;
@use "sass:map" as map;

@include mat.core();

////Material theme (для правильного отображения компонентов необходимо скопировать)
//// Расширяем с помощью merge коллекции цветов material
$pd-blue-palette: map.deep-merge(mat.$blue-palette, (
	"pd-blue": $main-blue,
	//"pd-blue": cl(var(--pd-base-01)),
	//"pd-blue": var(--main-blue),
	//"pd-blue": cl(--main-blue),
	//"pd-blue": #{cl(--main-blue)},
	//"pd-blue": var(--pd-base-01),
	"pd-aux-blue": var(--pd-aux-03),
	contrast:(
		"pd-blue": var(--pd-base-08),
		"pd-aux-blue": var(--pd-base-08),
	)
));
$pd-red-palette: map.deep-merge(mat.$red-palette, (
	"pd-red": var(--pd-base-02),
	"pd-aux-red": var(--pd-aux-01),
	contrast:(
		"pd-red": var(--pd-base-08),
		"pd-aux-red": var(--pd-base-08),
	)
));
$pd-green-palette: map.deep-merge(mat.$green-palette, (
	"pd-green": var(--pd-base-05),
	"pd-aux-green": var(--pd-aux-02),
	contrast:(
		"pd-green": var(--pd-base-08),
		"pd-aux-green": var(--pd-base-08),
	)
));
$pd-orange-palette: map.deep-merge(mat.$orange-palette, (
	"pd-orange": var(--pd-base-04),
	"pd-aux-orange": var(--pd-aux-04),
	contrast:(
		"pd-orange": var(--pd-base-08),
		"pd-aux-orange": var(--pd-base-08),
	)
));
$pd-gray-palette: map.deep-merge(mat.$gray-palette, (
	"pd-black": var(--pd-base-03),
	"pd-aux-gray": var(--pd-aux-07),
	contrast:(
		"pd-black": var(--pd-base-08),
		"pd-aux-gray": var(--pd-base-08),
	)
));
$pd-purple-palette: map.deep-merge(mat.$purple-palette, (
	"pd-violet": var(--pd-base-06),
	"pd-aux-violet": var(--pd-aux-05),
	contrast:(
		"pd-violet": var(--pd-base-08),
		"pd-aux-violet": var(--pd-base-08),
	)
));
/*
  Цвета из палитры, для материал. Основные цвета темы в _variabels
  $primary-color; $warn-color; $accent-color;
*/
$primary: mat.define-palette($pd-blue-palette, "pd-blue");
$warn: mat.define-palette($pd-red-palette, "pd-red");
$accent: mat.define-palette($pd-orange-palette, "pd-orange");


$main-typography: mat.define-typography-config(
	$font-family: var(--pd-font-family),
		//Дефолтные стили скопировал, чтобы не путаться
	$display-4: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
	$display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
	$display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
	$display-1: mat.define-typography-level(34px, 40px, 400),
	$headline: mat.define-typography-level(24px, 32px, 400),
	$title: mat.define-typography-level(20px, 32px, 500),
		//Свои
		// subheading-2 == side-nav-menu
		//$subheading-2: mat.define-typography-level($side-menu-item-font-size, $side-menu-item-line-height, $side-menu-item-font-weight),
	$subheading-2: mat.define-typography-level(var(--pd-side-menu-fs), var(--pd-side-menu-lh), var(--pd-side-menu-fw)),
		//Дефолт
	$subheading-1: mat.define-typography-level(15px, 24px, 400),
	$body-2: mat.define-typography-level(14px, 24px, 500),
		//Свои
	$body-1: mat.define-typography-level(13px),
		//caption == mat-header-cell
	$caption: mat.define-typography-level(13px),
		//Не только button но и tab-links
		//$button: mat.define-typography-level($button-font-size, $button-line-height, $button-font-weight),
		//$input: mat.define-typography-level($input-font-size, $input-line-height, $input-font-weight),
	$button: mat.define-typography-level(var(--pd-button-fs), var(--pd-button-lh), var(--pd-button-fw)),
	$input: mat.define-typography-level(var(--pd-input-fs), 1.2, var(--pd-input-fw)),
);


$main-theme: create-theme($primary, $accent, $warn, --pd-base-03, $typography: $main-typography);
//$main-theme: create-theme($primary, $accent, $warn, $font-color, $typography: $main-typography);
//$main-theme: create-theme($primary, $accent, $warn, #34303D, $typography: $main-typography);

@include mat.all-component-themes($main-theme);

html {
	height: 100%;
	-webkit-tap-highlight-color: transparent;
	scroll-behavior: smooth;

	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		background: transparent;
		width: 0;
		height: 0;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
}

body {
	margin: 0;
	height: 100%;
	min-height: 100%;
	color: var(--pd-base-03);
	font-family: "Roboto", sans-serif !important;
	//background-color: var(--pd-aux-bg-03);

	//--pd-primary: var(--pd-base-01);
	--pd-primary: #{cl(--pd-base-01)};
	--pd-aux-primary: var(--pd-aux-03);

}

app-root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.pd-auth,
.pd-public {
	background-color: var(--pd-base-08);
}

p, h1, h2, h3 {
	margin: 0;
}

@media screen and (max-width: 980px) {
	.pd-root.side-menu-opened {
		overflow: hidden;
	}
}
