@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@import "src/styles/mixins/mixins";

//Профиль  пользователя
.pd-profile {
	display: flex;
	flex-direction: column;
	gap: 20px;

	@include sm-375 {
		width: fit-content;
	}

	&-title {
		@include h1_22_bold;
		width: fit-content;
		max-width: 700px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&-subtitle {
		@include h2_19_medium;
		display: flex;
		align-items: center;
		gap: 16px;

		.pd-icon {
			flex-shrink: 0;
			align-self: flex-start;
		}
	}

	&-block-no-content-wrapper {
		display: flex;
		flex-direction: column;
		gap: 11px;
	}

	&-text-medium {
		@include text_15_medium;
	}

	&-table-experience-title {
		@include text_16_input_bold;
	}

	&-phone,
	&-email {
		@include h3_17_medium;
		white-space: nowrap;
	}

	&-text,
	&-text .pd-link {
		@include text_15_regular;

		&.gray {
			color: var(--pd-text-02);
		}
	}

	//Обертка для тайтла
	&-title-wrapper {
		display: flex;
		gap: 16px;
		align-items: center;
	}

	&-table-row-title {
		color: var(--pd-text-02);
		@include text_16_regular;
	}

	&-experiences-description {
		line-height: 20px;
	}

	//Основные стили плитки
	&-plate {
		box-sizing: border-box;
		padding: 20px;
		border-radius: 4px;
		background-color: var(--pd-base-08);
	}

	//Стили для плиток в виде аккардеона (замена &-plate)
	&-block {
		background-color: var(--pd-base-08);
		border-radius: 4px;
		--expansion-panel-body-padding: 10px 20px 20px;
		--expansion-panel-header-padding: 20px;
	}

	//Титул
	&-block-title {
		@include h1_22_bold;
	}

	//Обертка с контентом внутри аккардеона
	&-block-body {
		display: flex;
		flex-direction: column;
		gap: var(--profile-block-body-gap);
	}

	&-app-alert-wrapper {
		margin-bottom: 20px;
	}

	//Строки с плитками
	&-row {
		flex-direction: row;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin-bottom: 20px;
	}

	//Объединенная строка с brief & rating
	&-join-row {
		display: flex;


		.gap {
			gap: 10px;
		}
	}

	//Объединенная строка с brief & rating
	&-join-row-with-gap {
		display: flex;
		column-gap: 10px;

		@media screen and (max-width: 1280px) {
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
		}

		@include xl-min-1450 {
			display: flex;
			column-gap: 20px;
		}
	}

	//Колонки внутри плитки обо мне
	&-column-with-gap {
		display: flex;
		flex-direction: column;
		gap: 30px;
		//Для подсказки "заполните профиль"
		.pd-snackbar {
			z-index: 2;
		}
	}

	// inner-column: колонка внутри плитки
	&-inner-column {
		display: flex;
		flex-direction: column;
		//Таблица опыта работы пользователя
		.pd-profile-table-experience & {
			align-items: flex-start;
		}
	}

	//Плитка с контактной информацией
	&-brief {
		width: 100%;
		border-radius: 4px 0 0 4px;
		border-right: none;
		display: flex;
		gap: 25px;
	}

	//Плитка с кнопками изменить пароль и поддержка
	&-settings {
		--profile-block-body-gap: 25px;
		--expansion-panel-body-padding: 5px 20px 20px;

		& .pd-button-transparent {
			max-width: fit-content;
		}
	}


	//Плитка рейтинг
	&-rating {
		grid-area: rating;
		padding-top: 24px;
		border-radius: 0 4px 4px 0;
		align-items: center;
		width: 100%
	}

	//Флекс строка рейтинга с большой звездой
	&-rating-content {
		display: flex;
		gap: 57px;
		flex-direction: row;
		justify-content: center;

		.pd-rating-big-one-wrapper {
			left: -32px;
			top: 19px;
		}
	}

	&-rating-content.no-rating {
		align-items: flex-start;

		.pd-rating-big-one-wrapper {
			left: 0;
			top: 0;
		}
	}

	//Строка со звездами и названием качества
	&-rating-row {
		flex-grow: 0;
		display: flex;
		gap: 17px;
		align-items: center;
	}

	&-rating-title {
		margin-bottom: 40px;
	}

	&-rating-row:not(:last-of-type) {
		margin-bottom: 12px;
	}

	&-rating-one-small {
		display: none;
	}

	&-sub-categories {
		.pd-profile-title-wrapper {
			margin-bottom: 20px;
		}
	}

	//Плитка с успешно завершенными работами
	&-successful-works {
		.pd-profile-title-wrapper {
			margin-bottom: 20px;
		}

		//Обертка для значения появляется только при разрешении < 980px
		&-value-wrapper {
			margin: -10px 0 20px;
			@include h1_40_bold;
			line-height: 47px;
		}

		//Цвета для процентов
		&-value {
			&.main-green {
				color: var(--pd-base-05);
			}

			&.main-red {
				color: var(--pd-base-02);
			}

			&.main-orange {
				color: var(--pd-base-04);
			}
		}
	}

	//Рейтинг категорий
	&-categories-rate {
		--expansion-panel-body-padding: 0 20px 20px;

		& > .mat-expansion-panel-header.mat-expanded {
			--expansion-panel-header-padding: 20px 20px 0;
			margin-bottom: 20px;
		}
	}

	//Обертка для subtitle + кнопка редактировать
	&-about-top-wrapper {
		display: flex;
		align-items: center;
		gap: 16px;
	}

	//В блоке обо мне специалиста
	&-about-top-wrapper {
		margin-bottom: 25px;

		&.empty {
			margin-bottom: 0;
		}
	}

	/*
	  Скиллы
	*/
	&-skills-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	&-sub-categories-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	&-no-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 17px;
		min-height: 263px;
		border: 1px solid var(--pd-aux-11);
		border-radius: 4px;

		&-text {
			color: var(--pd-primary);
		}

		&-icon.mat-icon {
			color: var(--pd-base-03);
			width: 100px;
			height: 100px;
		}

		//Кнопка добавления формы в диалоговом окне
		&-button {
			@include text_15_regular;
			color: var(--pd-primary);
			@include hover-supported() {
				.pd-icon.mat-icon {
					color: var(--pd-primary);
				}
			}

			.mat-button-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 17px;
			}
		}

		&-button.mat-icon-button:not(.mat-button-disabled) {
			@include hover-supported() {
				color: var(--pd-font-color);
			}
		}
	}

	&-no-successful-works {

		&-title {
			font-size: 22px;
			font-weight: 700;
			margin-right: 8px;
		}
		
		&-wrapper {
			margin-top: 20px;
			display: flex;
			align-items: center;
		}
	}
}

/*
  Аватар
*/
.pd-profile-avatar {
	&-wrapper {
		position: relative;
		background-color: var(--pd-aux-bg-03);
		width: 180px;
		height: 180px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-image {
		display: block;
		width: 180px;
		height: 180px;
		border-radius: 4px;
		object-fit: cover;
		position: absolute;
	}

	.pd-company &-image {
		width: 176px;
		height: 160px;
	}

	&-empty-icon {
		&.mat-icon {
			width: 63px;
			height: 63px;
		}

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--pd-aux-12);

		&.can-update {
			top: 41px;
			transform: translate(-50%, 0);
		}
	}

	&-btn.mat-raised-button {
		position: absolute;
		bottom: 32px;
		left: 50%;
		transform: translateX(-50%);
	}

	.initial-letters-host {
		&.can-update {
			margin-top: -32px;
		}
	}
}

.pd-initial-letters {
	&.small {
		font-size: 18px;
		height: 22px;
	}

	&.middle {
		font-size: 30px;
		height: 32px;
		line-height: 32px;
	}

	&.big {
		font-size: 72px;
	}
}

/*
  Контакты
*/
.pd-contacts {
	&-row {
		display: flex;
		gap: 20px;
	}

	&-row-title {
		display: flex;
		gap: 16px;
		align-items: center;
	}

	&-item {
		display: flex;
		gap: 16px;
		align-items: center;
	}
}

//Информация о пользователе (ФИО и т.д)
.pd-personal {
	&-column {
		display: flex;
		flex-direction: column;
		gap: 26px;
		height: 100%;
	}

	//Ряд с тайтлом, кнопкой и иконкой
	&-top-row {
		display: flex;
		gap: 16px;
		align-items: center;

		.pd-icon {
			flex-shrink: 0;
		}
	}

	&-top-column {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;

	}

	//Статус открыт для работы
	&-state {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		height: 100%;
		@include text_13_table_regular;
		padding: 5px 10px;
		border-radius: 30px;

		&.open {
			color: var(--pd-base-05);
			background-color: var(--pd-aux-02);
		}

		&.closed {
			color: var(--pd-base-02);
			background-color: var(--pd-aux-01);
		}

		//Статус ИНН
		&.confirmed {
			color: var(--pd-base-05);
			background-color: var(--pd-aux-02);
			border: 0;
		}

		&.unconfirmed {
			color: var(--pd-text-02);
			background-color: var(--pd-aux-bg-02);
			border: 0;
		}
	}
}

//Компонент с рейтингом категорий
.pd-categories-rate {
	width: 100%;
	--expansion-panel-body-padding: 20px 0 0px;
	--expansion-panel-header-padding: 0;
	display: flex;
	flex-direction: column;
	gap: 15px;
	position: relative;

	.mat-expansion-panel.pd-expansion-panel.mat-expanded {
		overflow: visible;
		animation-name: visibleExpansionPanel;
		animation-duration: 1s;
	}

	app-progress-bar {
		z-index: 5;
	}

	app-app-user-category-works {
		z-index: 4;
		position: relative;
	}

	&-header {
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;
	}

	&-title-wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		z-index: 6;

		.pd-expansion-panel:not(.mat-expanded) &:not(:hover) {
			color: var(--pd-text-02);
		}
	}

	&-title {
		@include text_15_regular;
	}

	.pd-progress-bar-fill {
		opacity: 0.7;
	}

	//Высота обертки при загрузке карточек
	.pd-basis-card-empty {
		min-height: 100px;
	}
}

/*
   Таблица
*/
.pd-profile-table {
	margin-left: 12px;
	border-left: 1px solid var(--pd-aux-12);

	.pd-profile-text {
		line-height: 20px;
	}

	.mat-row {
		border-bottom: none;
		min-height: unset;

	}

	&-description-name,
	&-experience-title {
		margin-bottom: 4px;
		line-height: 20px;
	}

	&-level-row {
		margin-bottom: 18px;

		&.hidden {
			display: none;
		}
	}

	&-period-row {
		align-items: flex-start;

		&:not(:last-of-type) {
			margin-bottom: 15px;
		}
	}

	&-level-row.hidden + &-period-row {
		margin-bottom: 19px;
	}

	&-description-full-row:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.mat-cell:first-of-type {
		padding-left: 24px;
		overflow: visible;
	}

	&-header-row.mat-header-row {
		border: none;
		min-height: unset;
	}


	&-period.mat-cell {
		max-width: 160px;

		@include sm-610 {
			max-width: 300px;
		}
	}

	&-pointed-cell {
		position: relative;

		&:before {
			position: absolute;
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: var(--pd-aux-13);
			left: 0;
			top: 50%;
			//57% из-за бордера
			transform: translate(-57%, -50%);
		}

		&.top-point:before {
			top: 0;
			transform: translate(-57%, 50%);
		}
	}

	&-description-full {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		color: var(--pd-text-02);
	}

	&-text.speciality {
		@include text_13_checkbox_medium;
	}

	&-experience &-period.mat-cell,
	&-period .pd-profile-text {
		@include text_13_table_regular;
	}

	&-experience &-period.mat-cell {
		flex-direction: column;
		align-items: flex-start;
	}

	&-experience-delta {
		@include text_13_table_regular;
		color: var(--pd-text-02);
		margin-top: 5px;
	}
}

.pd-profile-edit-btn {
	position: absolute;
    top: 54px;
	left: 0;
	z-index: 2;
	width: 180px;
	background-color: transparent;
	color: var(--pd-base-08);

	&:hover {
		opacity: 0.9;
		cursor: pointer;
	}
}

@media screen and (max-width: 1700px) {
	.pd-profile {
		&-row {
			justify-content: center;
		}

		&-join-row {
			flex-direction: column;
			gap: 20px;
		}

		&-brief,
		&-rating {
			border-radius: 4px;
		}

		&-inner-column {
			width: unset;
		}

		&-rating-content {
			justify-content: flex-start;
		}

		&-rating-content.no-rating {
			.pd-profile-rating-title {
				margin-bottom: 20px;
			}
		}

		&-rating-title {
			margin-bottom: 33.5px;
		}
	}
}

@media screen and (max-width: 980px) {
	.pd-profile {
		&-brief {
			flex-direction: column;
			gap: 30px;
		}

		&-plate {
			max-width: 100%;

		}

		&-rating {
			padding-bottom: 20px;
		}

		//Убираем большую звезду
		&-rating-one-big {
			display: none;
		}

		&-rating-one-small {
			display: block;
		}

		&-rating-title {
			display: flex;
			align-items: center;
			gap: 18px;
		}

		&-rating-content.no-rating &-rating-one-small {
			display: none;
		}
	}
	.pd-personal {
		&-column {
			justify-content: flex-start;
		}
	}
}

@media screen and (max-width: 800px) {
	.pd-profile {
		&-contacts-row {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}

@media screen and (max-width: 610px) {
	.pd-profile {
		&-rating &-text {
			@include text_13_table_regular;
		}

		&-experiences &-subtitle {
			max-width: 228px;
		}

		&-hard-skills &-subtitle {
			max-width: 220px;
		}

		&-education-additionals &-subtitle {
			max-width: 289px;
		}

		&-subtitle {
			align-items: flex-start;
			.pd-icon {
				flex-shrink: 0;
				position: relative;
				top: -1px;
			}
		}

		&-about-top-wrapper {
			align-items: flex-start;
			justify-content: space-between;

		}

		&-button-text {
			display: none;
		}
	}
	.pd-contacts {
		&-row {
			display: flex;
			flex-direction: column;
			gap: 16px;
			margin-top: 0;
			//Больший отступ для клиента
			.pd-personal-column-client & {
				gap: 20px;
			}
		}
	}

	.pd-profile-table {
		margin-left: 0;
		border: none;

		.mat-cell:first-of-type {
			padding-left: 0;
		}

		&-pointed-cell:before {
			display: none;
		}

		&-level-row {
			margin-bottom: 24px;
		}

		&-period {
			margin-bottom: 8px;
		}

		&-period-row.mat-row {
			flex-direction: column;
		}

		&-experience-delta {
			margin-top: 7px;
		}
	}
}

@keyframes visibleExpansionPanel {
	from {
		overflow: hidden;
	}
	to {
		overflow: visible;
	}
}
