@use "node_modules/@profdepo-ui/core/styles/mixins" as *;
@use "node_modules/@profdepo-ui/core/styles/variables" as *;

//Страница с соглашениями/политиками и т.д.
.pd-terms{

  &-container{
    margin-top: 100px;
    padding: 30px 0;
    font-family: var(--pd-font-family);
    .pd-link{
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      flex: none;
    }
  }
  &-main-title{
    @include h1_22_bold;
    margin-bottom: 36px;
  }
  &-main-subtitle{
    @include text_15_regular;
    margin-bottom: 40px;
  }
  &-article-title,
  &-article-subtitle,
  &-text
  {
    display: flex;
    align-items: center;
    h2, h3, p {
      font-size: inherit;
      line-height: inherit;
      font-style: inherit;
      font-weight: inherit;
    }
  }
  &-article{
    margin: 40px 0;
  }
  &-article:last-of-type{
    margin: 40px 0 0;
  }
  &-article-title{
    @include h2_19_medium;
    margin-bottom: 40px;
  }
  &-article-subtitle{
    @include h3_r17_regular;
    margin-bottom: 26px;
    margin-top: 26px;
  }
  &-article-list{
    margin: 26px 0;
  }
  &-bold-span{
    font-weight: 700;
  }
  &-text{
    @include text_15_regular;
    margin-bottom: 12px;
  }
  &-requisites{
    margin-bottom: 26px;
  }
  &-contacts,
  &-requisites{
   .pd-terms-text{
     margin-bottom: 0;
     line-height: 22px;
   }
    .pd-link{
      text-decoration: none;
    }
  }

  &-current-term{
    color: var(--pd-text-02);
    @include text_13_table_regular;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    &.without-margin-bottom{
      margin-bottom: 0;
    }
  }
  //Ссылка с рекламой на http://www.it-lex.ru/
  &-advs-link{
    @include text_13_table_regular;
    color: var(--pd-aux-12);
    @include hover-supported(){
      color: var(--pd-text-02);
    }
  }
  &-prefix-letter,
  &-prefix-empty,
  &-prefix-numbers,
  &-prefix-bold-word{
    align-self: flex-start;
    margin-right: 19px;
    width: 30px;
    flex-shrink: 0;
  }
  //Префикс с буквой/иконкой/точкой
  &-prefix-letter{
    display: flex;
    height: 26px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-color: var(--pd-aux-bg-02);
  }
  //Пустой префикс
  &-prefix-empty{
    display: block;
    height: 1px;
  }
  //Префикс с числом
  &-prefix-numbers{
    font-weight: 500;
    display: block;
  }
  //Префикс со словом
  &-prefix-bold-word{
    white-space: nowrap;
    font-weight: 700;
    font-size: 13px;
    display: block;
  }
}

@media screen and (max-width: 980px){
  .pd-terms-container{
    margin-top: 67px;
  }
}

@media screen and (max-width: 790px) {
  .pd-terms-container{
    margin-top: 56px;
  }
}
