@use "node_modules/@profdepo-ui/core/styles/variables" as *;
@use "node_modules/@profdepo-ui/core/styles/mixins" as *;

.pd-image-cropper{
  --image-cropper-height: 263px;
  &.without-cropper{
    object-fit: cover;
    max-width: 100%;
  }
  &.hidden{
    display: none;
  }
  &-wrapper{
    position: relative;
    height: 263px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    border-radius: 4px;
    border: none;
    &.has-no-photo{
      border: 1px solid var(--pd-aux-11);
    }
  }
    //Цвет фона за кроппером
    --cropper-outline-color: rgba(255,255,255, .7);

    .ngx-ic-source-image{
      border-radius: 4px;
    }
    .ngx-ic-cropper{
      //Убираем пунктирные линии
      color: transparent !important;
      //Скрываем квадратики в углах -> отображаем уголки
      .ngx-ic-resize.ngx-ic-topleft,
      .ngx-ic-resize.ngx-ic-topright,
      .ngx-ic-resize.ngx-ic-bottomleft,
      .ngx-ic-resize.ngx-ic-bottomright,
      {
        .ngx-ic-square{
          background-color: transparent !important;
          border-color: transparent !important;
          position: relative;
          &:before,
          &:after{
            position: absolute;
            content: '';
            background-color: var(--pd-base-03);
          }
          &:after{
            width: 12px;
            height: 6px;
          }
          &:before{
            width: 6px;
            height: 12px;
          }
        }
      }
      .ngx-ic-resize.ngx-ic-topleft .ngx-ic-square{
        &:before,
        &:after{
          top: 0;
          left: 0;
        }
      }
      .ngx-ic-resize.ngx-ic-topright .ngx-ic-square{
        &:before,
        &:after{
          top: 0;
          right: 0;
        }
      }
      .ngx-ic-resize.ngx-ic-bottomright .ngx-ic-square{
        &:before,
        &:after{
          bottom: 0;
          right: 0;
        }
      }
      .ngx-ic-resize.ngx-ic-bottomleft .ngx-ic-square{
        &:before,
        &:after{
          bottom: 0;
          left: 0;
        }
      }
      //Квадратки по центру границы -> прямоугольники
      .ngx-ic-resize.ngx-ic-top,
      .ngx-ic-resize.ngx-ic-bottom{
        left: 50% !important;
        transform: translateX(-50%);
        .ngx-ic-square{
          width: 12px !important;
          background-color: var(--pd-base-03) !important;
          border: none !important;
        }
      }
      .ngx-ic-resize.ngx-ic-right,
      .ngx-ic-resize.ngx-ic-left,
      {
        top: 50% !important;
        transform: translateY(-50%);
        .ngx-ic-square{
          height: 12px !important;
          background-color: var(--pd-base-03) !important;
          border: none !important;
        }
      }
    }
}
@media screen and (max-width: 610px){
  .pd-image-cropper{
    --image-cropper-height: unset;
    &-wrapper{
      height: 100%;
      min-height: 100%;
      justify-self: stretch;
    }
  }
}
